import { Box, Stack } from '@mui/material'
import { FC, useState } from 'react'
import {
	Button,
	CreateButton,
	Datagrid,
	DateField,
	ExportButton,
	FilterButton,
	FilterForm,
	FunctionField,
	ImageField,
	List,
	TextField,
	TextInput,
	TopToolbar,
	UrlField,
	useRecordContext,
} from 'react-admin'

const postFilters = [<TextInput label="За ім'ям" source='fullname' />]

const ListActions = () => (
	<TopToolbar>
		<FilterButton filters={postFilters} />
		<CreateButton label='Створити' />
		<ExportButton label='Експорт' />
	</TopToolbar>
)

const TagFilter = () => (
	<Stack direction='row' justifyContent='space-between' alignItems='end'>
		<FilterForm filters={postFilters} />
	</Stack>
)

const DescriptionField: FC<{ label: string }> = ({ label }) => {
	const { about } = useRecordContext()
	const [text, setText] = useState(
		about.length < 100 ? about : `${about.slice(0, 100)}...`
	)
	const [pressed, setPressed] = useState(false)

	return (
		<FunctionField
			label={label}
			render={() => (
				<Box display='flex' flexDirection='column'>
					<span> {text} </span>
					{about.length >= 100 && (
						<Box display='flex' alignItems='flex-start'>
							<Button
								sx={{ mt: 1 }}
								label={pressed ? 'Сховати' : 'Більше'}
								onClick={() => {
									setPressed(!pressed)
									setText(pressed ? `${about.slice(0, 100)}...` : about)
								}}
							/>
						</Box>
					)}
				</Box>
			)}
		/>
	)
}

export const PsychologistsList = () => {
	return (
		<List
			title='Психологи'
			sx={{ tableLayout: 'fixed' }}
			actions={<ListActions />}
			filters={<TagFilter />}
			// actions={<ListActions />}
			// filters={<TagFilter />}
			sort={{ field: 'created_at', order: 'DESC' }}
		>
			<Datagrid
				rowClick='edit'
				// bulkActionButtons={<PreferenceButtons label='' type='psychologists' />}
				sx={{
					width: '100%',
					backgroundColor: '#f5f5f5',
				}}
			>
				<TextField label='Автор' source='fullname' />
				<DescriptionField label='Опис' />
				<TextField label='Кваліфікація' source='qualification' />
				<UrlField label='Facebook' source='facebook' />
				<UrlField label='Instagram' source='instagram' />
				<UrlField label='Email' source='email' />

				<ImageField label='Фото' source='photo' />
				<DateField label='Дата створення' source='created_at' />
				{/* <UrlField label='URL' source='video_url' /> */}
				{/* <PreferenceButtons type='videos' label='Налаштування' /> */}
			</Datagrid>
		</List>
	)
}
