import { useEffect, useState } from 'react';
import {
  Edit,
  SimpleForm,
  SelectInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRecordContext,
  useDataProvider,
  DateInput,
  TextInput,
} from 'react-admin';

import Box from '@mui/material/Box';
import RequestStatusInput from '../../components/common/inputs/RequestStatusInput';

export const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton variant="contained" color="secondary" />
  </Toolbar>
);

const Title = () => {
  const record = useRecordContext();
  return <span> {record?.name} </span>;
};

const CustomToolBar = () => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton label="Зберегти" />
      <DeleteButton label="Видалити" />
    </Toolbar>
  );
};

const ConnectGroupInput = () => {
  const [connectGroupList, setConnectGroupList] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList('connect_group_list', {})
      .then(({ data }) => {
        const array = [];

        for (const element of data) {
          array.push({ id: element.id, name: element.name });
        }
        
        setConnectGroupList(array);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return <SelectInput sx={{ ml: 3 }} label="Конект група" source="group_id" choices={connectGroupList} />
};

const VolontierEdit = () => {
  return (
    <Edit title={<Title />}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flex="row" width="100%">
            <DateInput sx={{ ml: 3 }} label="Відправлено" disabled source="created_at" />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <TextInput sx={{ ml: 3 }} label="ПІБ" disabled source="name" />
            <TextInput sx={{ ml: 3 }} label="Пошта" disabled source="email" />
            <TextInput sx={{ ml: 3 }} label="Телефон" disabled source="phone" />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <TextInput sx={{ ml: 3 }} label="Країна" disabled source="country" />
            <TextInput sx={{ ml: 3 }} label="Регіон" disabled source="region" />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <TextInput sx={{ ml: 3 }} label="Таланти" multiline disabled source="talents" />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <RequestStatusInput />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default VolontierEdit;
