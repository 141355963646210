import { useContext, useEffect, useState } from 'react'
import { Admin, Resource } from 'react-admin'

import { createHashHistory } from 'history'

import Spinner from '../../components/common/Spinner'

import authProvider from '../../providers/authProvider'
import { customBuildFields } from '../../providers/dataProvider'

import buildHasuraProvider from 'ra-data-hasura'

import CustomLayout from '../../components/common/MenuLayout'

import apolloClient from '../../lib/apollo'

import Dashboard from '../dashboard'

import { AccessContext } from '../../containers/access-provider'

import { AnalyticsList } from '../analytics'
import { BanRequestsEdit, BanRequestsList } from '../banRequests'
import { BannersEdit, BannersList } from '../banners'
import BannersCreate from '../banners/BannersCreate'
import { CategoriesCreate, CategoriesEdit, CategoriesList } from '../categories'
import { CoachRequestsEdit, CoachRequestsList } from '../coachRequests'
import {
	ConnectGroupCreate,
	ConnectGroupEdit,
	ConnectGroupList,
} from '../connectGroups'
import { ConnectRequestsEdit, ConnectRequestsList } from '../connectRequests'
import { InfostandList } from '../infostand'
import { MeetingsCreate, MeetingsEdit, MeetingsList } from '../meetings'
import { MeetingsRequestsEdit, MeetingsRequestsList } from '../meetingsRequests'
import { PrayerWallEdit, PrayerWallList } from '../prayerWall'
import { PsychologistsCreate } from '../psychologists/PsychologistsCreate'
import { PsychologistsEdit } from '../psychologists/PsychologistsEdit'
import { PsychologistsList } from '../psychologists/PsychologistsList'
import { RolesCreate, RolesEdit, RolesList } from '../roles'
import { RolesNamesCreate, RolesNamesEdit, RolesNamesList } from '../rolesNames'
import {
	SubCategoriesCreate,
	SubCategoriesEdit,
	SubCategoriesList,
} from '../subCategories'
import { UsersCreate, UsersEdit, UsersList } from '../users'
import { VideosCreate, VideosEdit, VideosList } from '../videos'
import { VolontierEdit, VolontierGroupsList } from '../volontierGroup'

const history = createHashHistory()

const AdminPanel = () => {
	const { accesses } = useContext(AccessContext)
	const [dataProvider, setDataProvider] = useState(null)

	useEffect(() => {
		const buildDataProvider = async () => {
			const dataProvider = await buildHasuraProvider(
				{
					client: apolloClient,
				},
				{ buildFields: customBuildFields }
			)
			setDataProvider(() => dataProvider)
		}
		buildDataProvider()
	}, [])

	if (!dataProvider) {
		return <Spinner size={60} />
	}

	const usersProps =
		accesses['users'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const videosProps =
		accesses['videos'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const categoriesProps =
		accesses['categories'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const subCategoriesProps =
		accesses['sub_categories'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const bannersProps =
		accesses['banners'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const connectProps =
		accesses['connect_group'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const connectListProps =
		accesses['connect_group_list'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const volontierProps =
		accesses['volontier_group'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const meetingsProps =
		accesses['meetings'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const prayersProps =
		accesses['prayers'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const coachAlertsProps =
		accesses['alerts_to_coach'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const rolesProps =
		accesses['roles'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const rolesNamesProps =
		accesses['roles_names'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const infostandProps =
		accesses['infostand'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const analyticsProps =
		accesses['analytics'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const meetingsRequestsProps =
		accesses['meetings_requests'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const banRequestsProps =
		accesses['ban_requests'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }
	const psychologistsProps =
		accesses['psychologists'] || accesses['all']
			? {}
			: { list: <></>, edit: <></>, create: <></> }

	return (
		<Admin
			loginPage={false}
			dashboard={Dashboard}
			dataProvider={dataProvider}
			authProvider={authProvider}
			layout={CustomLayout}
			history={history}
		>
			<Resource
				name='users'
				list={UsersList}
				edit={UsersEdit}
				create={UsersCreate}
				{...usersProps}
			/>

			<Resource
				name='videos'
				list={VideosList}
				edit={VideosEdit}
				create={VideosCreate}
				{...videosProps}
			/>
			<Resource
				name='categories'
				list={CategoriesList}
				edit={CategoriesEdit}
				create={CategoriesCreate}
				{...categoriesProps}
			/>
			<Resource
				name='sub_categories'
				list={SubCategoriesList}
				edit={SubCategoriesEdit}
				create={SubCategoriesCreate}
				{...subCategoriesProps}
			/>
			<Resource
				name='banners'
				list={BannersList}
				edit={BannersEdit}
				create={BannersCreate}
				{...bannersProps}
			/>

			<Resource
				name='connect_group'
				list={ConnectRequestsList}
				edit={ConnectRequestsEdit}
				{...connectProps}
			/>
			<Resource
				name='connect_group_list'
				list={ConnectGroupList}
				edit={ConnectGroupEdit}
				create={ConnectGroupCreate}
				{...connectListProps}
			/>
			<Resource
				name='volontier_group'
				list={VolontierGroupsList}
				edit={VolontierEdit}
				{...volontierProps}
			/>
			<Resource
				name='meetings'
				list={MeetingsList}
				edit={MeetingsEdit}
				create={MeetingsCreate}
				{...meetingsProps}
			/>
			<Resource
				name='prayers'
				list={PrayerWallList}
				edit={PrayerWallEdit}
				{...prayersProps}
			/>
			<Resource
				name='alerts_to_coach'
				list={CoachRequestsList}
				edit={CoachRequestsEdit}
				{...coachAlertsProps}
			/>
			<Resource
				name='roles'
				list={RolesList}
				edit={RolesEdit}
				create={RolesCreate}
				{...rolesProps}
			/>
			<Resource
				name='roles_names'
				list={RolesNamesList}
				edit={RolesNamesEdit}
				create={RolesNamesCreate}
				{...rolesNamesProps}
			/>
			<Resource
				name='psychologists'
				list={PsychologistsList}
				edit={PsychologistsEdit}
				create={PsychologistsCreate}
				{...psychologistsProps}
			/>
			<Resource name='infostand' list={InfostandList} {...infostandProps} />
			<Resource name='analytics' list={AnalyticsList} {...analyticsProps} />
			<Resource
				name='meetings_requests'
				list={MeetingsRequestsList}
				edit={MeetingsRequestsEdit}
				{...meetingsRequestsProps}
			/>
			<Resource
				name='ban_requests'
				list={BanRequestsList}
				edit={BanRequestsEdit}
				{...banRequestsProps}
			/>
		</Admin>
	)
}

export default AdminPanel
