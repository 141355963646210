import {
  Edit,
  SimpleForm,
  Toolbar,
  DeleteButton,
  useRecordContext,
  TextInput,
  DateInput,
  Button,
  required, useUpdate, useUpdateMany, useNotify, useRedirect, useRefresh, useDataProvider, SelectInput, useGetList
} from 'react-admin';

import Box from '@mui/material/Box';
import {useFormContext} from "react-hook-form";
import {useEffect, useState} from "react";
import CoachSelectCheckbox from "../../components/common/CoachSelectCheckbox";

const Title = () => {
  const record = useRecordContext();
  return <span> {record?.name} </span>;
};

const CustomToolBar = ({currentCoaches}) => {
  const { id } = useRecordContext();
  const { getValues } = useFormContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const { data: Users, isLoading: ConnectUsersLoading } = useGetList('users');

  const [update, { isLoading }] = useUpdate();
  const [updateMany, { isLoading: isLoadingMany }] = useUpdateMany();
  const UpdateHandler = () => {
    const { name, name_en, main_coach_id } = getValues();

    if (!name) {
      notify('Введіть назву українською', { type: 'error' });
      return;
    } else if (!name_en) {
      notify('Введіть назву англійською', { type: 'error' });
      return;
    }

    const connectGroupUser = Users.filter(el => el.connect_id === id && el.access_level !== "coach");

    const ChangeToId = [];
    const ChangeToNull = [];
    const usersForChangingCoach = [];

    for ( const coach of currentCoaches.selectedCoaches ) {
      coach.enabled ? ChangeToId.push(coach.user_id) : ChangeToNull.push(coach.user_id);
    }

    for ( const user of connectGroupUser ) {
      usersForChangingCoach.push(user.id);
    }

    try {
      update('connect_group_list', { id, data: { name, name_en, main_coach_id } });

      if ( ChangeToId.length !== 0 ) {
        updateMany('users', {
          ids: ChangeToId,
          data: { connect_id: id },
        });
      }

      if ( ChangeToNull.length !== 0 ) {
        updateMany('users', {
          ids: ChangeToNull,
          data: { connect_id: null },
        });
      }

      if ( usersForChangingCoach.length !== 0 ) {
        updateMany('users', {
          ids: usersForChangingCoach,
          data: { coach_id: main_coach_id },
        });
      }

      redirect('/connect_group_list');

      setTimeout(() => {
        refresh();
      }, 1000);

    } catch (e) {
      notify('Помилка!', { type: 'error' });
      console.log(e);
    }
  }

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button label="Зберегти"
              disabled={isLoading && isLoadingMany && ConnectUsersLoading}
              size="medium" onClick={UpdateHandler} />
      <DeleteButton label="Видалити" />
    </Toolbar>
  );
};

const CoachInput = () => {
  const { id } = useRecordContext();
  const [coachUsers, setCoachUsers] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
        .getList('users', {})
        .then(({ data }) => {
          const coachUsers = data.filter((el) => el.access_level === 'coach' && el.connect_id === id );

          const array = [];

          for (const coach of coachUsers) {
            array.push({ id: coach.id, name: coach.name });
          }

          setCoachUsers(array);
        })
        .catch((error) => {
          console.log(error);
        });
  }, []);

  return <SelectInput sx={{ ml: 3, width: '42%' }} label="Головний пастор" source="main_coach_id" choices={coachUsers} />;
};
const ConnectGroupEdit = () => {
  const [choosedCoaches, setChoosedCoaches] = useState({});
  const ToolBarItem = () => {
    return <CustomToolBar currentCoaches={choosedCoaches}/>
  }

  return (
    <Edit title={<Title />}>
      <SimpleForm toolbar={<ToolBarItem/>}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flex="row" width="100%">
            <DateInput sx={{ ml: 3 }} label="Дата створення" disabled source="created_at" />
            <TextInput sx={{ ml: 3, width: '22vw' }} label="ID" disabled source="id" />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <TextInput sx={{ ml: 3 }} label="Назва конект групи Укр" source="name" validate={required()} />
            <TextInput sx={{ ml: 3 }} label="Назва конект групи Eng" source="name_en" validate={required()} />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <CoachInput />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <CoachSelectCheckbox choosedCoaches={choosedCoaches} setChoosedCoaches={e => setChoosedCoaches(e)}/>
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default ConnectGroupEdit;
