import { Box, Stack } from '@mui/material'
import { FC, useState } from 'react'
import {
	BooleanField,
	BooleanInput,
	BulkDeleteButton,
	Button,
	Datagrid,
	DateField,
	DateTimeInput,
	ExportButton,
	FilterButton,
	FilterForm,
	FunctionField,
	List,
	NumberInput,
	ReferenceField,
	SelectInput,
	TextField,
	TextInput,
	TopToolbar,
	required,
	useRecordContext,
} from 'react-admin'

import PrayerField from '../../components/common/PrayerField'
import PreferenceButtons from '../../components/common/PreferenceButtons'

const getWeekFilter = (value: string): string => {
	const today = new Date()

	if (value === '1') {
		const lastWeek = new Date()
		lastWeek.setDate(today.getDate() - 7)
		return lastWeek.toISOString()
	} else if (value === '2') {
		const twoWeeksAgo = new Date()
		twoWeeksAgo.setDate(today.getDate() - 14)
		return twoWeeksAgo.toISOString()
	} else if (value === '3') {
		const threeWeeksAgo = new Date()
		threeWeeksAgo.setDate(today.getDate() - 21)
		return threeWeeksAgo.toISOString()
	}

	return ''
}

const weekOptions = [
	{ id: getWeekFilter('1'), name: 'Більше тижня тому' },
	{ id: getWeekFilter('2'), name: 'Більше 2 тижнів тому' },
	{ id: getWeekFilter('3'), name: 'Більше 3 тижнів тому' },
]

const postFilters = [
	<SelectInput
		label='За типом'
		source='type'
		validate={required()}
		choices={[
			{ id: 'prayer', name: 'Молитва' },
			{ id: 'praise', name: 'Свідотство' },
		]}
	/>,
	<TextInput label='За текстом' source='text' />,
	<NumberInput label='За лайками' source='press_count' />,
	<BooleanInput label='За анонімністю' source='isAnon' sx={{ ml: 2, mb: 1 }} />,
	<DateTimeInput label="За датою віправлення" source="created_at" />,
	<SelectInput
		source='created_at@_lte'
		label='За останні тиждні'
		choices={weekOptions}
		filter={getWeekFilter('1')}
	/>,
]

const ListActions = () => (
	<TopToolbar>
		<FilterButton filters={postFilters} />
		<ExportButton label='Експорт' />
	</TopToolbar>
)

const TagFilter = () => (
	<Stack direction='row' justifyContent='space-between' alignItems='end'>
		<FilterForm filters={postFilters} />
	</Stack>
)

const BulkActionButtons = () => {
	return (
		<Box mb={1}>
			<BulkDeleteButton label='Видалити' />
		</Box>
	)
}

const DescriptionField: FC<{ label: string }> = ({ label }) => {
	const { text: description } = useRecordContext()
	const max_size = 100

	const [text, setText] = useState(
		description.length < max_size
			? description
			: `${description.slice(0, max_size)}...`
	)
	const [pressed, setPressed] = useState(false)

	return (
		<FunctionField
			label={label}
			render={() => (
				<Box display='flex' flexDirection='column'>
					<span> {text} </span>
					{description.length < max_size ? (
						<></>
					) : (
						<Box display='flex' alignItems='flex-start'>
							<Button
								sx={{ mt: 1 }}
								label={pressed ? 'Сховати' : 'Більше'}
								onClick={() => {
									setPressed(!pressed)
									setText(
										pressed
											? `${description.slice(0, max_size)}...`
											: description
									)
								}}
							/>
						</Box>
					)}
				</Box>
			)}
		/>
	)
}

const PrayerWallList = () => {
	return (
		<List
			title='Молитовна стіна'
			sx={{ tableLayout: 'fixed' }}
			actions={<ListActions />}
			filters={<TagFilter />}
			sort={{ field: 'created_at', order: 'ASC' }}
		>
			<Datagrid
				bulkActionButtons={<BulkActionButtons />}
				sx={{
					width: '100%',
					backgroundColor: '#f5f5f5',
				}}
			>
				<PrayerField label='Тип' />

				<DescriptionField label='Текст' />

				<ReferenceField label='Відправник' source='user_id' reference='users'>
					<TextField source='name' />
				</ReferenceField>

				<BooleanField
					label='Анонимність'
					source='isAnon'
					valueLabelTrue='Так'
					valueLabelFalse='Ні'
				/>

				<TextField label='Лайки' source='press_count' />

				<DateField label='Відправлено' source='created_at' showTime />

				<PreferenceButtons type="prayers" label='Налаштування' />
			</Datagrid>
		</List>
	)
}

export default PrayerWallList
