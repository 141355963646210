import { Stack } from '@mui/material'
import {
	CreateButton,
	Datagrid,
	DateField,
	EmailField,
	ExportButton,
	FilterButton,
	FilterForm,
	List,
	ReferenceField,
	SelectInput,
	TextField,
	TextInput,
	TopToolbar,
	required,
} from 'react-admin'

import AccessLevelField from '../../components/common/AccessLevelField'
import BulkActionButtons from '../../components/common/BulkActionButtons'
//import CoachInput from "../../components/common/inputs/CoachInput";

//need will be change to dynamic data
const coachUsers = [
	{ id: 'fu2o7B2YkXN2v4aBNxWgXklWOM62', name: 'Kirill Sazonov' },
	{ id: 'e4LJlW21jtZo9N2fMUl0CconVXF3', name: 'Евгеній Шитов' },
	{ id: 'jqSuaYGtcWZqtIwV4YkW7rYUkes2', name: 'Slava Sachenko' },
	{ id: 'QFOIJ9O8iAYSZh2B3SxVhTcrKiG2', name: 'Ольга Валеріївна Притула' },
	{ id: '2hjnx8KhBtToN5uwn46rSaoumLQ2', name: 'Слюсаренко Марія' },
	{ id: '5yFtSDbeOASNUCkzpZKoz1D2epe2', name: 'Равнушкін Юрій Юрійович' },
]

const postFilters = [
	<TextInput label="За ім'ям" source='name' />,
	<TextInput label='За поштою' source='email' />,
	<TextInput label='За телефоном' source='phone' />,
	<TextInput label='За країною' source='country' />,
	<TextInput label='За регіоном' source='region' />,
	<TextInput label='За церквою' source='church' />,
	<SelectInput
		label='За доступом'
		source='access_level'
		validate={required()}
		choices={[
			{ id: 'user', name: 'Користувач' },
			{ id: 'coach', name: 'Пастор' },
			{ id: 'moderator', name: 'Модератор' },
		]}
	/>,
	<SelectInput label='За пастором' source='coach_id' choices={coachUsers} />,
]

const ListActions = () => (
	<TopToolbar>
		<FilterButton filters={postFilters} />
		<CreateButton label='Створити' />
		<ExportButton label='Експорт' />
	</TopToolbar>
)

const TagFilter = () => (
	<Stack direction='row' justifyContent='space-between' alignItems='end'>
		<FilterForm filters={postFilters} />
	</Stack>
)

const UsersList = () => {
	return (
		<List
			title='Користувачі'
			sx={{ tableLayout: 'fixed' }}
			actions={<ListActions />}
			filters={<TagFilter />}
		>
			<Datagrid
				bulkActionButtons={<BulkActionButtons />}
				rowClick='edit'
				sx={{
					backgroundColor: '#f5f5f5',
				}}
			>
				<TextField label='ПІБ' source='name' />
				<EmailField label='Пошта' source='email' />
				<TextField label='Телефон' source='phone' />
				<TextField label='Країна' source='country' />
				<TextField label='Регіон' source='region' />,
				<TextField label='Церква' source='church' />
				<DateField label='Зареєстрован' source='created_at' />
				<AccessLevelField label='Рівень доступу' />
				<ReferenceField label='Пастор' source='coach_id' reference='users'>
					<TextField source='name' />
				</ReferenceField>
			</Datagrid>
		</List>
	)
}

export default UsersList
