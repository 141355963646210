import {useContext, useEffect, useState} from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    DateInput,
    Toolbar,
    DeleteButton,
    useRecordContext,
    useDataProvider,
    required, BooleanInput, Button, useSaveContext, useUpdate, useRedirect
} from 'react-admin';

import Box from '@mui/material/Box';
import CountryInput from '../../components/common/inputs/CountryInput';
import {useFormContext} from "react-hook-form";
import { httpsCallable } from "firebase/functions";
import { fns } from "../../lib/firebase";
import {AccessContext} from "../../containers/access-provider";
import CoachInput from "../../components/common/inputs/CoachInput";
const UserTitle = () => {
  const record = useRecordContext();
  return <span> {record?.name} </span>;
};

const CustomSaveButton = () => {
    const [saveLoading, setSaveLoading] = useState(null);

    const [update, { isLoading }] = useUpdate();
    const saveContext = useSaveContext();
    const { id, banned } = useRecordContext();
    const form = useFormContext();
    const redirect = useRedirect();

    const SaveHandler = async () => {
        setSaveLoading(true);
        const values = form.getValues();

        if ( banned !== values.banned ) {
            const banUser = httpsCallable(fns, "banUser");
            const unbanUser = httpsCallable(fns, "unbanUser");
            values.banned ? await banUser({uid: id}) : await unbanUser({uid: id});
        }

        await update('users', { id, data: { ...values } });
        setSaveLoading(false);
        redirect('/users');
    }

    return <Button label="Зберегти" disabled={saveContext?.saving || isLoading || saveLoading} size="medium" onClick={SaveHandler} />
}

const CustomToolBar = () => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <CustomSaveButton label="Зберегти" />
      <DeleteButton label="Видалити" />
    </Toolbar>
  );
};

const RoleInput = () => {
    const [roles, setRoles] = useState([]);
    const { accesses } = useContext(AccessContext);
    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider
            .getList('roles_names', {})
            .then(({ data }) => {
                const array = [];

                for (const element of data) {
                    array.push({ id: element.id, name: element.name });
                }

                setRoles(array);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return accesses["roles"] || accesses["roles_names"] || accesses["all"] ? (
        <SelectInput sx={{ ml: 5 }} label="Роль" source="role_id" choices={roles} />
    ) : <></>
};

const ConnectGroupInput = () => {
    const [connectGroups, setConnectGroups] = useState([]);

    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider
            .getList('connect_group_list', {})
            .then(({ data }) => {
                const array = [];

                for (const element of data) {
                    array.push({ id: element.id, name: element.name });
                }

                setConnectGroups(array);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return <SelectInput sx={{ ml: 5 }} label="Конект група" source="connect_id" choices={connectGroups} />
};

const UsersEdit = () => {
  return (
    <Edit title={<UserTitle />}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Box display="flex" flexDirection="column" sx={{ml: 3}}>
          <Box display="flex" flex="row" width="100%" >
            <TextInput sx={{ mb: 5 }} disabled label="ID" source="id" />
            <TextInput sx={{ ml: 5 }} disabled label="Пошта" source="email" />
            <DateInput sx={{ ml: 10 }} disabled label="Зареєстрован" source="created_at" />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <TextInput label="ПІБ" source="name" validate={required()} />
            <TextInput sx={{ ml: 5 }} label="Церква" source="church" />
            <CoachInput />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <TextInput label="Телефон" source="phone" />
            <SelectInput
              sx={{ ml: 5 }}
              label="Стать"
              source="gender"
              emptyText="Бажаю не вказувати"
              choices={[
                { id: 'male', name: 'Чоловік' },
                { id: 'female', name: 'Жінка' },
              ]}
            />
            <ConnectGroupInput />
            <RoleInput />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <CountryInput />
            <TextInput sx={{ ml: 5 }} label="Регіон" source="region" />
            <SelectInput
              sx={{ ml: 5 }}
              validate={required()}
              label="Рівень доступу"
              source="access_level"
              choices={[
                { id: 'user', name: 'Користувач' },
                { id: 'coach', name: 'Пастор' },
                { id: 'moderator', name: 'Модератор' },
              ]}
            />
              <BooleanInput
                  sx={{ ml: 5, mt: 2 }}
                  label="Забанено"
                  source="banned"
              />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default UsersEdit;
