import {useEffect, useState} from "react";
import {useDataProvider, useRecordContext} from "react-admin";
import {Checkbox, FormControl, FormControlLabel, InputLabel, Select} from "@mui/material";

const CoachSelectCheckbox = ({choosedCoaches, setChoosedCoaches}) => {
    const [coachUsers, setCoachUsers] = useState([]);
    const { id: connect_id } = useRecordContext();
    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider
            .getList('users', {})
            .then(({ data }) => {
                const coachUsers = data.filter((el) => el.access_level === 'coach');

                const array = [];
                const selected_coaches_array = [];

                for (const coach of coachUsers) {
                    array.push({ id: coach.id, name: coach.name, connect_id: coach.connect_id });

                    if ( coach.connect_id === connect_id ) {
                        selected_coaches_array.push({user_id: coach.id, enabled: true });
                    }
                }

                setChoosedCoaches({
                    selectedCoaches: selected_coaches_array,
                    connect_id
                });

                setCoachUsers(array);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    const UpdateActiveCoaches = (e, element) => {
        if ( e.target.checked ) {
            const index = choosedCoaches.selectedCoaches.findIndex((el) => {
                return el.user_id === element.id;
            } );

            if ( index === -1 ) {
                setChoosedCoaches({
                    selectedCoaches: [...choosedCoaches.selectedCoaches, { user_id: element.id, enabled: true }],
                    connect_id
                });
            } else {
                choosedCoaches.selectedCoaches[index].enabled = true;
                const modified_array = choosedCoaches.selectedCoaches;

                setChoosedCoaches({
                    selectedCoaches: modified_array,
                    connect_id
                });
            }
        } else {
            if ( choosedCoaches?.selectedCoaches?.length !== 0 ) {
                const index = choosedCoaches.selectedCoaches.findIndex((el) => {
                    return el.user_id === element.id;
                });

                if ( index !== -1 ) {

                    if ( element.connect_id === connect_id ) {
                        choosedCoaches.selectedCoaches[index].enabled = false;
                        const modified_array = choosedCoaches.selectedCoaches;
                        setChoosedCoaches({
                            selectedCoaches: modified_array,
                            connect_id
                        });
                    } else {
                        choosedCoaches.selectedCoaches.splice(index, 1);
                        setChoosedCoaches({
                            selectedCoaches: choosedCoaches.selectedCoaches,
                            connect_id
                        });
                    }
                }
            }
        }
    }

    const checkChoosed = (element) => {
        const result = choosedCoaches?.selectedCoaches.find(choosed => choosed.user_id === element.id && choosed.enabled);
        return Boolean(result);
    }

    return (
        <FormControl variant="filled" sx={{ ml: 3, width: '45%' }}>
            <InputLabel id="select-label">Обрати пасторів</InputLabel>
            <Select
                labelId="select-label"
                defaultValue={""}
                sx={{display: "flex", flexDirection: "column"}}
            >
                {
                    coachUsers.map((element, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    defaultChecked={
                                        element.connect_id === connect_id
                                        || checkChoosed(element)
                                    }
                                />
                            }
                            label={element.name}
                            onChange={(e) => UpdateActiveCoaches(e, element)}
                            sx={{display: "flex", flexDirection: "row", px: 2}}
                        />
                    ))
                }
            </Select>
        </FormControl>
    )
};

export default CoachSelectCheckbox;