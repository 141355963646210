import { Stack } from '@mui/material'
import {
	BooleanInput,
	CreateButton,
	Datagrid,
	DateField,
	DateInput,
	ExportButton,
	FilterButton,
	FilterForm,
	FormTab,
	ImageField,
	List,
	NumberInput,
	ReferenceManyField,
	TabbedForm,
	TabbedFormTabs,
	TextField,
	TextInput,
	TopToolbar,
	UrlField,
} from 'react-admin'
import BulkActionButtons from '../../components/common/BulkActionButtons'

const postFilters = [
	<TextInput label='За назвою' source='name_ua' />,
	<TextInput label='За типом' source='type' />,
	<NumberInput label='За порядком' source='index' />,
	<BooleanInput
		label='За показом іконки'
		source='is_icon_shown'
		sx={{ ml: 2, mb: 1 }}
	/>,
	<DateInput label='За датою створення' source='created_at' />,
]

const ListActions = () => (
	<TopToolbar>
		<FilterButton filters={postFilters} />
		<CreateButton label='Створити' />
		<ExportButton label='Експорт' />
	</TopToolbar>
)

const TagFilter = () => (
	<Stack direction='row' justifyContent='space-between' alignItems='end'>
		<FilterForm filters={postFilters} />
	</Stack>
)
const CategoriesList = () => {
	return (
		<List
			title='Фільтри'
			sx={{ tableLayout: 'fixed' }}
			actions={<ListActions />}
			filters={<TagFilter />}
		>
			<TabbedForm
				tabs={<TabbedFormTabs variant='scrollable' scrollButtons='auto' />}
				syncWithLocation={false}
			>
				<FormTab label='Категорії'>
					<Datagrid
						rowClick='edit'
						bulkActionButtons={<BulkActionButtons />}
						sx={{
							width: '100%',
							backgroundColor: '#f5f5f5',
						}}
					>
						<ImageField label='Фото' source='cover' />
						<TextField label='Тип' source='type' />
						<TextField label='Позиція' source='order' />
						<TextField label='Назва' source='title' />
						<DateField label='Дата створення' source='created_at' />
					</Datagrid>
				</FormTab>
				<FormTab label='Відео за категоріями'>
					<Datagrid bulkActionButtons={false}>
						<TextField label='Назва' source='title' />
						<ReferenceManyField
							label='Відео'
							reference='videos'
							target='category_id'
						>
							<Datagrid bulkActionButtons={false}>
								<ImageField label='Фото' source='cover' />
								<TextField label='Назва' source='title' />
								<TextField label='Опис' source='description' />
								{/* <TextField
									label='Опис'
									source='subCategoriesByParentCategoryId.name'
								/> */}
								<UrlField label='URL' source='video_url' />
							</Datagrid>
						</ReferenceManyField>
					</Datagrid>
				</FormTab>
			</TabbedForm>
		</List>
	)
}

export default CategoriesList
