import { FC, useRef, useState } from 'react'
import {
	BooleanInput,
	Button,
	Create,
	SimpleForm,
	Toolbar,
	useCreate,
	useNotify,
	useRedirect,
} from 'react-admin'

import { useFormContext } from 'react-hook-form'

import {
	Alert,
	Button as MuiButton,
	TextField as MuiField,
} from '@mui/material'
import Box from '@mui/material/Box'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { httpsCallable } from 'firebase/functions'
import FilterInput from '../../components/common/inputs/FilterInput'
import {
	createAnanalytics,
	download,
	fns,
	storageRef,
	UploadVideoSnippet,
} from '../../lib/firebase'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 400,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))

const CustomToolBar: FC<{ video: any }> = ({ video }) => {
	const form = useFormContext()
	const notify = useNotify()
	const [create, { isLoading }] = useCreate(undefined, undefined, {
		onSuccess: async data => {
			const createScheduledEvent = httpsCallable(fns, 'createScheduledEvent')

			if (data.id) {
				await createAnanalytics(data.id)
			}

			if (data.status === 'upcoming') {
				const duration_array = data.duration.split(':')

				let timeForArchiving = dayjs(data.premiere_at)

				if (duration_array.length === 3) {
					// its have hours, minutes and seconds
					timeForArchiving = dayjs(data.premiere_at)
						.add(Number(duration_array[0]), 'hours')
						.add(Number(duration_array[1]), 'minutes')
						.add(Number(duration_array[2]), 'seconds')
				}

				if (duration_array.length === 2) {
					// its have only minutes and seconds
					timeForArchiving = dayjs(data.premiere_at)
						.add(Number(duration_array[0]), 'minutes')
						.add(Number(duration_array[1]), 'seconds')
				}

				await createScheduledEvent({
					video_id: data.id,
					status: 'live',
					schedule_at: dayjs(data.premiere_at).toISOString(),
				})

				await createScheduledEvent({
					video_id: data.id,
					status: 'archive',
					schedule_at: timeForArchiving.toISOString(),
				})
			} else if (data.status === 'live') {
				const duration_array = data.duration.split(':')

				let timeForArchiving = dayjs(data.created_at)

				if (duration_array.length === 3) {
					// its have hours, minutes and seconds
					timeForArchiving = dayjs(data.created_at)
						.add(Number(duration_array[0]), 'hours')
						.add(Number(duration_array[1]), 'minutes')
						.add(Number(duration_array[2]), 'seconds')
				}

				if (duration_array.length === 2) {
					// its have only minutes and seconds
					timeForArchiving = dayjs(data.created_at)
						.add(Number(duration_array[0]), 'minutes')
						.add(Number(duration_array[1]), 'seconds')
				}

				await createScheduledEvent({
					video_id: data.id,
					status: 'archive',
					schedule_at: timeForArchiving.toISOString(),
				})
			}
		},
	})

	const redirect = useRedirect()
	const SaveHandler = async () => {
		try {
			const values = form.getValues()

			let snippet = video.snippet

			if (video.snippetBlob) {
				const snapshot = await UploadVideoSnippet(
					video.title.replace(/\s/g, ''),
					video.snippetBlob
				)
				snippet = await download(storageRef(snapshot.metadata.fullPath))
			}

			const data = {
				video_url: video.url,
				title: video.title,
				cover: snippet,
				shown: values.shown,
				description: video.description,
				category_id: values.category_id ?? null,
				sub_category_ids: values.sub_category_ids,
				sub_category_id: values.sub_category_id ?? null,
				author: values.author,
				author_link: video.author_link,
			}

			await create('videos', { data })
			redirect('/videos')
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<Button
				size='medium'
				disabled={isLoading}
				label='Зберегти'
				onClick={SaveHandler}
			/>
		</Toolbar>
	)
}

const VideosCreate = () => {
	const notify = useNotify()
	const [video, setVideo] = useState({
		url: '',
		title: '',
		description: '',
		duration: '',
		status: '',
		snippet: '',
		snippetBlob: null,
		author: '',
		author_link: '',
	})

	const [blobError, setBlobError] = useState<string>('')

	const ImageInputRef = useRef<HTMLInputElement>(null)

	const handleUpload = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			const { height, width } = img

			if ((width / height).toFixed(2) !== '1.78') {
				setBlobError('Додайте зображення з відношенням сторін 16 на 9')
				return
			} else if (
				height < 720 ||
				height > 1080 ||
				width < 1280 ||
				width > 1920
			) {
				setBlobError(
					'Зображення повинно бути не менше 1280x720 та не більше 1920х1080 пікселів'
				)
				return
			} else {
				setBlobError('')
				setVideo(prevState => {
					return {
						...prevState,
						snippet: img.src,
						snippetBlob: event.target.files[0],
					}
				})
			}
		}
	}

	// const handler = async (url: string) => {
	//   const video = new YoutubeVideo(url);
	//   try {
	//     await video.fetchYoutubeData();
	//   } catch (e) {
	//     if (e instanceof ValidationError) {
	//       notify(e.message, { type: 'error' });
	//       console.log(e.message);
	//     }
	//   }
	//
	//   setVideo({ ...video.getInstance, url, status: '', snippetBlob: null });
	// };

	const changeVideoByURL = (e: any) => {
		const url = e.target.value

		setVideo({ ...video, url })
		setBlobError('')

		// if (url) {
		//   handler(url);
		// }
	}

	return (
		<Create title='Нове відео' sx={{ tableLayout: 'fixed' }}>
			<SimpleForm toolbar={<CustomToolBar video={video} />}>
				<Box display='flex' flexDirection='column' sx={{ ml: 3, mr: 3 }}>
					<Box display='flex' flex='row' width='100%'>
						<MuiField
							fullWidth
							label='URL'
							required
							value={video.url}
							onChange={changeVideoByURL}
						/>
					</Box>
					<Box display='flex' flex='row' width='100%'>
						<BooleanInput label='Показується' source='shown' />
					</Box>
					{/*<BannerSetup/>*/}
					<Box display='flex' flex='row' width='100%' sx={{ mt: 3 }}>
						<FilterInput />
					</Box>

					<Box sx={{ mt: 3 }}>
						<MuiField
							sx={{ width: '100%' }}
							multiline
							label='Назва'
							required
							value={video.title}
							onChange={e => setVideo({ ...video, title: e.target.value })}
						/>
					</Box>
					<Box sx={{ mt: 3 }}>
						<MuiField
							multiline
							sx={{ width: '100%' }}
							label='Опис'
							value={video.description}
							onChange={e =>
								setVideo({ ...video, description: e.target.value })
							}
						/>
					</Box>

					<Box sx={{ mt: 3 }}>
						<MuiField
							sx={{ width: '100%' }}
							multiline
							label='Автор'
							value={video.author}
							onChange={e => setVideo({ ...video, author: e.target.value })}
						/>
					</Box>
					<Box sx={{ mt: 3 }}>
						<MuiField
							sx={{ width: '100%' }}
							multiline
							label='Лінк на автора'
							value={video.author_link}
							onChange={e =>
								setVideo({ ...video, author_link: e.target.value })
							}
						/>
					</Box>

					{/*Cover*/}
					<Box
						display='flex'
						flex='row'
						width='100%'
						sx={{ mt: 3 }}
						alignItems='center'
					>
						<Box sx={{ width: '70%' }}>
							<input
								type='file'
								accept='image/*'
								style={{ display: 'none' }}
								ref={ImageInputRef}
								onChange={handleUpload}
							/>
							<img
								style={{ width: '100%', height: '100%' }}
								src={video.snippet}
								alt={video.title}
							/>

							{blobError && (
								<Alert severity='error' sx={{ mb: 2 }}>
									{blobError}
								</Alert>
							)}

							<Box
								display={'flex'}
								flexDirection={'row'}
								alignItems={'center'}
								justifyContent={'space-between'}
								gap={2}
							>
								<MuiButton
									variant='contained'
									sx={{
										background: '#703eff',
										lineHeight: '18px',
										'&:hover': {
											background: '#703eff',
										},
									}}
									onClick={() => {
										if (ImageInputRef.current) {
											ImageInputRef.current.click()
										}
									}}
								>
									Змінити картинку
								</MuiButton>
								<Typography
									sx={{
										fontSize: 14,
										textAlign: 'end',
										width: '70%',
										whiteSpace: 'pre-line',
									}}
								>
									{
										'Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів'
									}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
			</SimpleForm>
		</Create>
	)
}

export default VideosCreate
