import { useEffect, useState } from 'react'
import { SelectArrayInput, SelectInput, useDataProvider } from 'react-admin'

const FilterInput = () => {
	const [categories, setCategories] = useState([])
	const [subcategories, setSubCategories] = useState([])
	const [psychologists, setPsychologists] = useState([])

	const dataProvider = useDataProvider()

	useEffect(() => {
		dataProvider
			.getList('categories', {})
			.then(({ data }) => {
				let array = []

				for (const element of data) {
					array.push({ id: element.id, name: element.title })
				}

				setCategories(array)
			})
			.catch(error => {
				console.log(error)
			})

		dataProvider
			.getList('sub_categories', {})
			.then(({ data }) => {
				let array = []

				for (const element of data) {
					array.push({ id: element.name, name: element.name })
				}

				setSubCategories(array)
			})
			.catch(error => {
				console.log(error)
			})

		dataProvider
			.getList('psychologists', {})
			.then(({ data }) => {
				let array = []

				for (const element of data) {
					array.push({ id: element.fullname, name: element.fullname })
				}

				setPsychologists(array)
			})
			.catch(error => {
				console.log(error)
			})
	}, [])

	return (
		<>
			<SelectInput
				sx={{ width: '100%' }}
				label='Категорія'
				source='category_id'
				choices={categories}
			/>
			<SelectInput
				sx={{ ml: 5, width: '100%' }}
				label='Автор'
				source='author'
				choices={psychologists}
			/>
			<SelectArrayInput
				sx={{ ml: 5, width: '100%' }}
				source='sub_category_ids'
				choices={subcategories}
				label='Підкатегорії'
			/>
			{/*<SelectInput sx={{ml: 5, width: '100%' }} label="Підкатегорія" source="sub_category_id" choices={subcategories} />*/}
		</>
	)
}

export default FilterInput
