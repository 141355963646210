import { Stack } from '@mui/material';
import {
    List,
    TextField,
    TopToolbar,
    Datagrid,
    CreateButton,
    ExportButton,
    TextInput,
    FilterForm,
    NumberField,
    FilterButton,
    NumberInput,
    DateInput,
    BooleanField,
    ImageField, BooleanInput, DateField, ReferenceManyField, UrlField,
    TabbedForm, FormTab, TabbedFormTabs
} from 'react-admin';
import BulkActionButtons from '../../components/common/BulkActionButtons';

const postFilters = [
  <TextInput label="За назвою" source="name_ua" />,
  <TextInput label="За типом" source="type" />,
  <NumberInput label="За порядком" source="index" />,
  <BooleanInput label="За показом іконки" source="is_icon_shown" sx={{ ml: 2, mb: 1 }}/>,
  <DateInput label="За датою створення" source="created_at" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton filters={postFilters} />
    <CreateButton label="Створити" />
    <ExportButton label="Експорт" />
  </TopToolbar>
);

const TagFilter = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="end">
    <FilterForm filters={postFilters} />
  </Stack>
);
const SubCategoriesList = () => {
  return (
    <List
      title="Фільтри"
      sx={{ tableLayout: 'fixed' }}
      actions={<ListActions />}
      filters={<TagFilter />}
    >
        <TabbedForm tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />} syncWithLocation={false}>
            <FormTab label="Підкатегорії">
                <Datagrid
                    rowClick="edit"
                    bulkActionButtons={<BulkActionButtons />}
                    sx={{
                        width: '100%',
                        backgroundColor: '#f5f5f5',
                    }}>
                    <TextField label="Тип" source="type" />
                    <TextField label="Назва" source="name" />
                    <TextField label="Назва Категорії" source="category.title" />
                    <DateField label="Дата створення" source="created_at" />
                </Datagrid>
            </FormTab >
            <FormTab  label="Відео за під категоріями">
                <Datagrid bulkActionButtons={false}>
                    <TextField label="Тип" source="type" />
                    <TextField label="Назва" source="name" />
                    {/*<ReferenceManyField label="Відео" reference="sub_categories" target="parent_category_id">*/}
                    {/*    <Datagrid bulkActionButtons={false}>*/}
                    {/*        <TextField label="Назва" source="name" />*/}
                    {/*        <TextField label="Опис" source="description" />*/}
                    {/*        <TextField label="Опис" source="video.title" />*/}
                    {/*    </Datagrid>*/}
                    {/*</ReferenceManyField>*/}
                    <ReferenceManyField label="Відео" reference="videos" target="sub_category_id">
                        <Datagrid bulkActionButtons={false}>
                            {/*<ImageField label="Фото" source="snippet" />*/}
                            <TextField label="Назва" source="title" />
                            <TextField label="Опис" source="description" />
                            <UrlField label="URL" source="video_url" />
                        </Datagrid>
                    </ReferenceManyField>
                </Datagrid>
            </FormTab >
        </TabbedForm>
    </List>
  );
};

export default SubCategoriesList;
