import { Box, Stack } from '@mui/material';
import {
  List,
  TextField,
  EmailField,
  TopToolbar,
  Datagrid,
  ExportButton,
  DateField,
  ReferenceField,
  TextInput,
  SelectInput,
  FilterForm,
  FilterButton,
  required,
  BulkDeleteButton,
} from 'react-admin';

import UpdateStatusButton from '../../components/common/UpdateStatusButton';
import StatusField from '../../components/common/StatusField';

const postFilters = [
  <SelectInput
    label="За статусом"
    source="status"
    validate={required()}
    choices={[
      { id: 'pending', name: 'Очікування' },
      { id: 'accepted', name: 'Прийнято' },
    ]}
  />,
  <TextInput label="За ім'ям" source="name" defaultValue="user" />,
  <TextInput label="За поштою" source="email" />,
  <TextInput label="За телефоном" source="phone" />,
  <TextInput label="За повідомленням" source="message" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton filters={postFilters} />
    <ExportButton label="Експорт" />
  </TopToolbar>
);

const TagFilter = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="end">
    <FilterForm filters={postFilters} />
  </Stack>
);

const BulkActionButtons = () => {
  return (
    <Box mb={1}>
      <UpdateStatusButton status={'accepted'} resource={'alerts_to_coach'} />
      <UpdateStatusButton status={'pending'} resource={'alerts_to_coach'} />
      <BulkDeleteButton label="Видалити" />
    </Box>
  );
};

const CoachRequestsList = () => {
  return (
    <List
      title="Заявки до пасторів"
      sx={{ tableLayout: 'fixed' }}
      actions={<ListActions />}
      filters={<TagFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid
        rowClick="edit"
        bulkActionButtons={<BulkActionButtons />}
        sx={{
          width: '100%',
          backgroundColor: '#f5f5f5',
        }}>
        <StatusField label="Статус" accepted_label="Оброблено" />
        <TextField label="ПІБ" source="name" />
        <EmailField label="Пошта" source="email" />
        <TextField label="Телефон" source="phone" />
        <TextField label="Повідомлення" source="message" />
        <DateField label="Відправлено" source="created_at" showTime />
        <ReferenceField label="Користувач" source="user_id" reference="users">
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Пастор" source="coach_id" reference="users">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default CoachRequestsList;
