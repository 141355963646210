import { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { getInfostandChats, readAsAdmin } from "../../lib/firebase";
import { useDataProvider} from "react-admin";
import { CircularProgress, ListItemIcon, Box } from "@mui/material";
import Chat from './Chat';
import {UserI} from "./UserI";

const InfostandList = () => {
    const [chosen, setChosen] = useState<string | null>(null);
    const [chats, setChats] = useState<{id: string, unread_by_admin: number}[]>([]);
    const [users, setUsers] = useState<UserI[]>([]);

    const dataProvider = useDataProvider();

    const onChooseHandler = async (value: { id: string, unread_by_admin: number}) => {
        setChosen(value.id);

        if ( value.unread_by_admin > 0 ) {
            await readAsAdmin(value.id);
        }
    }

    const getRandomInt = (min: number, max: number): number => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    };

    useEffect(() => {
        const unsubscribe = getInfostandChats(setChats);
        return unsubscribe;
    }, []);

    useEffect(() => {
        dataProvider.getMany(
            'users',
            { ids: chats.map(el => el.id) }
        ).then(res => {
            const newUsers = res.data.map(el => {
                const number = getRandomInt(1, el.gender === "female" ? 27 : 22);

                return {
                    id: el.id,
                    name: el.name,
                    photo: el.photo? el.photo.photo_url : `/imgs/avatars/${number}-${el.gender ?? 'male'}.svg`
                }
            });

            setUsers(newUsers);
        });
    }, [chats]);

    if ( chosen ) {
        return (
            <Chat
                chosenUid={chosen}
                setChosen={(val: string) => setChosen(val) }
            />
        )
    }

    return (
        <List sx={{
            width: '100%',
            bgcolor: 'background.paper',
            color: 'rgba(0, 0, 0, 0.87)',
            mt: 2,
            pt: 2,
            pb: 2,
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderRadius: 4,
            boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
        }}>
            {chats.map((value) => {
                const labelId = `checkbox-list-label-${value}`;
                const user: UserI | undefined = users.find(el => el.id === value.id);

                return (
                    <ListItem
                        key={value.id}
                        sx={{mt: 1, mb: 1}}
                        disablePadding
                    >
                        {
                            user ? (
                                <ListItemButton role={undefined} onClick={() => onChooseHandler(value)} dense>
                                    <ListItemIcon>
                                        <img style={{ height: 40, width: 40, borderRadius: '50%' }} src={user.photo} alt={""} />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={user.name} />

                                    {
                                        value.unread_by_admin > 0 ? (
                                            <Box sx={{
                                                bgcolor: '#703eff',
                                                borderRadius: '50%',
                                                width: 34,
                                                height: 34,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: 'white'
                                            }}>
                                                {value.unread_by_admin}
                                            </Box>
                                        ) : <></>
                                    }

                                </ListItemButton>
                            ) : <></>
                                // (
                                //     <Box component="div" sx={{p: '0.5px', pl: 2}}>
                                //         <CircularProgress size={40} />
                                //     </Box>
                                // )
                        }
                    </ListItem>
                );
            })}
        </List>
    );
};


export default InfostandList;
