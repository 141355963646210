import { RemoveRedEye } from "@mui/icons-material"
import { Box, Chip, ListItemIcon } from "@mui/material"
import Link from "@mui/material/Link"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { FC, useEffect, useMemo, useState } from "react"
import { useDataProvider } from "react-admin"
import { useLocation, useNavigate } from "react-router-dom"
import { getLiveAnanytics, getLiveAnanyticsList, getMessagesByRoom, getWatchersList } from "../../lib/firebase"
import { UserI } from "../infostand/UserI"
import { IVideo, IVideoStats, IVideoStatus } from "./IVideoStats"


const AnalyticsList = () => {
    const [chosen, setChosen] = useState<string | null>(null);
    const [watchers, setWatchers] = useState<number[]>([]);
    const [videosList, setVideosList] = useState<string[]>([]);
    const [videos, setVideos] = useState<IVideo[]>([]);

    const location = useLocation();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (location.pathname.match(new RegExp('^(\\/analytics).+'))) {
            const id = location.pathname.split('/')[2];
            setChosen(id);
        } else {
            setChosen(null);
        }
    }, [location.pathname])

    useEffect(() => {
        const unsubscribe = getLiveAnanyticsList(setVideosList);
        return unsubscribe;
    }, []);

    useEffect(() => {
        if (videosList.length > 0) {
            dataProvider.getMany(
                'videos',
                {ids: videosList}
            ).then(res => {
                getWatchersList(videosList, setWatchers);
        
                const newVideos = res.data.map(el => ({
                    id: el.id,
                    title: el.title,
                    status: el.status,
                    snippet: el.snippet,
                    premiere_at: el.premiere_at,
                }));

                const newVideosSorted = newVideos
                    .sort((a, b) =>
                        new Date(b.premiere_at.slice(0, -6)).getTime()
                        - new Date(a.premiere_at.slice(0, -6)).getTime());
                
                setVideos(newVideosSorted);
            });
        }
    }, [videosList])

    const onChooseHandler = async (value: string) => {
        setChosen(value);
        navigate(`${location.pathname}/${value}`);
    }

    if (chosen) {
        return <LiveAnalytics id={chosen}/>
    }

    return (
        <List sx={{
            width: '100%',
            bgcolor: 'background.paper',
            color: 'rgba(0, 0, 0, 0.87)',
            mt: 2,
            pt: 2,
            pb: 2,
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderRadius: 4,
            boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
        }}>
            <Typography
                variant="h6"
                component="div"
                sx={{flexGrow: 1, fontWeight: 500, fontSize: 18, pl: 3, pb: 1}}
            >
                Аналітика трансляцій
            </Typography>

            {videos.map((value, index) => {
                //const video: IVideo | undefined = videos.find(el => el.id === value);
                return (
                    <VideoListItem
                        key={index}
                        video={value}
                        watchers={watchers[index]}
                        listItem={value.id}
                        onChoose={onChooseHandler}
                    />
                )
            })}
        </List>
    );
}

const VideoListItem: FC<{
    video: IVideo | undefined,
    watchers: number | undefined
    listItem: string,
    onChoose: (value: string) => Promise<void>
}
> = ({video, watchers, listItem, onChoose}) => {
  
    const labelId = `analytics-checkbox-list-label-${listItem}`;

    const [stats, setStats] = useState<IVideoStats | null>(null);

    useEffect(() => {
        return getLiveAnanytics(listItem, setStats);
    }, []);

    const totalViews = useMemo(() => {
        if(!stats) return;
        return (stats.max_anon_watchers ? stats.max_anon_watchers : 0)
               + (stats.users ? stats.users.length : 0)
               + (stats.archive ? stats.archive.anon_users : 0)
               + (stats.archive ? stats.archive.users.length : 0)
    }, [stats]);

    if (!video) {
        return <></>
    }

    return (
        <ListItem
            sx={{mt: 1, mb: 1, justifyContent: 'center', alignItems: 'center'}}
            disablePadding
        >
            <ListItemButton
                role={undefined}
                onClick={() => onChoose(listItem)}
                sx={{display: 'flex', justifyContent: 'space-between', p: 1, pl: 3, gap: 3}}
            >
                <Box sx={{display: 'flex', gap: 2}}>
                  <ListItemIcon>
                      <img style={{height: '100%', width: 120, borderRadius: 10}} src={video.snippet} alt={""}/>
                  </ListItemIcon>
                  <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                    <ListItemText id={labelId} primary={video.title}/>
                    <ListItemText id={labelId}
                                  primary={video.premiere_at
                                      .split("T")[0]
                                      .split("-")
                                      .reverse()
                                      .join(".")}/>
                  </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        gap: 1,
                        mr: 3,
                    }}
                >
                    <VideoStatusField status={video.status}/>
                    <Box
                        sx={{
                            backgroundColor: '#703eff',
                            pl: 2, pr: 4,
                            height: 40,
                            borderRadius: 2,
                            minWidth: 120,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 2,
                            color: 'white',
                            width: '120px'
                        }}
                    >
                        <RemoveRedEye/>
                        <Box>{totalViews}</Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%'
                            }}
                        >
                            <Typography
                                variant="h4"
                                component="h1"
                                sx={{color: 'white', fontSize: 16, fontWeight: 600}}
                            >
                                {watchers}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </ListItemButton>
        </ListItem>
    )
}

const VideoStatusField: FC<{ status: IVideoStatus }> = ({status}) => {

    const [statusChipColor, setStatusChipColor] = useState('rgb(69, 71, 78)');
    const [statusChipText, setStatusChipText] = useState('Архив');

    useEffect(() => {
        switch (status) {
            case 'archive':
                setStatusChipText('Архив');
                setStatusChipColor('rgb(69, 71, 78)');
                break;
            case 'upcoming':
                setStatusChipText('Заплановано');
                setStatusChipColor('rgb(34, 172, 106)');
                break;
            case 'live':
                setStatusChipText('Эфір');
                setStatusChipColor('rgb(187, 43, 43)');
                break;
            default:
                break;
        }
    }, [status]);

    return (
        <Chip
            label={statusChipText}
            sx={{
                px: 2,
                height: 40,
                minWidth: 120,
                background: statusChipColor,
                borderRadius: 2,
                color: 'white',
                fontSize: 16,
                fontWeight: 600,
            }}
        />
    );
};



const LiveAnalytics: FC<{ id: string }> = ({id}) => {

    const [stats, setStats] = useState<IVideoStats | null>(null);
    const [messagesCount, setMessagesCount] = useState<number | null>(null);
       

    useEffect(() => {
        getMessagesByRoom(id, (messages) => {
            setMessagesCount(messages.length);
        })

        return getLiveAnanytics(id, setStats);
    }, []);


    return (
        <Box
            sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: 4,
                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                width: '100%',
                p: 2
            }}
        >
            {
                stats && messagesCount && (
                    
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 3,
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    width: '100%',
                                    whiteSpace: "pre-line",
                                    ml: 1,
                                    mb: 3
                                }}
                            >
                                {"Статистика ефіру"}
                            </Typography>

                            <TextField
                                variant='filled'
                                disabled
                                sx={{width: '100%', pb: 3}}
                                label={'Кількість усіх користувачів, які дивилися зібрання'}
                                value={
                                (stats.max_anon_watchers ? stats.max_anon_watchers : 0)
                                    + (stats.users ? stats.users.length : 0)
                            }/>

                            <TextField
                                variant='filled'
                                disabled
                                sx={{width: '100%'}}
                                label={'Кількість анонімних користувачів, які дивилися зібрання'}
                                value={stats.max_anon_watchers ?? 0}
                            />

                            <TextField
                                variant='filled'
                                disabled
                                sx={{width: '100%'}}
                                label={'Кількість зареєстрованих користувачів, які дивилися зібрання'}
                                value={stats.users?.length ?? 0}
                            />

                            <TextField
                                variant='filled'
                                disabled
                                sx={{ width: '100%' }}
                                label={'Кількість повідомлень за час ефіру'}
                                value={messagesCount ?? 0}
                            />

                            <UsersList ids={stats.users} status={"live"} />

                            
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    width: '100%',
                                    whiteSpace: "pre-line",
                                    ml: 1,
                                    mb: 3
                                }}
                            >
                                {"Статистика запису ефіру"}
                            </Typography>

                            <TextField
                                variant='filled'
                                disabled
                                sx={{width: '100%', pb: 3}}
                                label={'Кількість усіх користувачів, що дивилися зібрання'}
                                value={stats.archive.anon_users + stats.archive.users.length ?? 0}
                            />

                            <TextField
                                variant='filled'
                                disabled
                                sx={{width: '100%'}}
                                label={'Кількість анонімних користувачів, що дивилися зібрання'}
                                value={stats.archive.anon_users ?? 0}
                            />

                            <TextField
                                variant='filled'
                                disabled
                                sx={{width: '100%'}}
                                label={'Кількість зареєстрованих користувачів, що дивилися зібрання'}
                                value={stats.archive.users.length ?? 0}
                            />

                            <UsersList ids={stats.archive.users} status={"archive"} />
                            
                        </Box>
                        
                    </Box>
                )
            }

        </Box>
    )
}

const UsersList: FC<{ ids: string[], status: string }> = ({ids, status}) => {
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const [users, setUsers] = useState<UserI[]>([]);

    const getRandomInt = (min: number, max: number): number => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    };

    useEffect(() => {
        if ((ids ? ids.length : 0) > 0) {
            dataProvider.getMany(
                'users',
                {ids}
            ).then(res => {
                const users = res.data.map(el => ({
                    id: el.id,
                    name: el.name,
                    photo: el.photo ? el.photo.photo_url : `/imgs/avatars/${getRandomInt(1, el.gender === "female" ? 27 : 22)}-${el.gender ?? 'male'}.svg`,
                }));

                setUsers(users);
            });
        }
    }, [ids]);

    if (users.length > 0) {
        return (
            <List sx={{
                width: '100%',
                bgcolor: 'background.paper',
                color: 'rgba(0, 0, 0, 0.87)',
                mt: 2,
                pt: 2,
                pb: 2,
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                borderRadius: 4,
                boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
            }}>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{flexGrow: 1, fontWeight: 500, fontSize: 18, pl: 3}}
                >
                    {status === "live" ? "Користувачі, які були присутні на трансляції:" : "Користувачі, що дивилися запис ефіру"}
                </Typography>

                {
                    users.map(user => {
                        const labelId = `analytics-user-checkbox-list-label-${user.id}`;
                        return (
                            <ListItem
                                key={labelId}
                                sx={{mt: 1, mb: 1, justifyContent: 'center', alignItems: 'center'}}
                                disablePadding
                            >
                                <ListItemButton
                                    role={undefined}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(`/users/${user.id}`)
                                    }}
                                    sx={{p: 1, pl: 3, gap: 3}}
                                >
                                    <ListItemIcon>
                                        <img style={{height: 40, width: 40, borderRadius: '50%'}} src={user.photo}
                                             alt={""}/>
                                    </ListItemIcon>
                                    <Link underline="none">
                                        {user.name}
                                    </Link>
                                </ListItemButton>
                            </ListItem>
                        );
                    })
                }
            </List>
        )
    }

    return <></>
}

export default AnalyticsList;
