import { Stack } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
	Button,
	CreateButton,
	Datagrid,
	DateInput,
	ExportButton,
	FilterButton,
	FilterForm,
	FunctionField,
	ImageField,
	List,
	SelectInput,
	TextField,
	TextInput,
	TopToolbar,
	UrlField,
	required,
	useDataProvider,
	useRecordContext,
} from 'react-admin'

import { Box } from '@mui/system'
import BulkActionButtons from '../../components/common/BulkActionButtons'
import PreferenceButtons from '../../components/common/PreferenceButtons'

const SelectSubCategoriesFilter = () => {
	const [subcategories, setSubCategories] = useState<any[]>([])

	const dataProvider = useDataProvider()

	useEffect(() => {
		dataProvider
			.getList('sub_categories', {
				pagination: {
					page: 1,
					perPage: 100,
				},
				sort: {
					field: 'id',
					order: 'ASC',
				},
				filter: '',
			})
			.then(({ data }: any) => {
				let array: any[] = []

				for (const element of data) {
					array.push({ id: element.id, name: element.name })
				}

				setSubCategories(array)
			})
			.catch(error => {
				console.log(error)
			})
	}, [])

	console.log('subcategories', subcategories)

	return (
		<SelectInput
			label='За підкатегорією'
			source='sub_category_ids'
			choices={subcategories}
			validate={required()}
		/>
	)
}

const postFilters = [
	<TextInput label='За посиланням' source='video_url' />,
	<TextInput label='За назвою' source='title' />,
	<TextInput label='За психологом' source='author' />,
	<DateInput label='За датою створення' source='created_at' />,
	<SelectInput
		label='За показом'
		source='shown'
		validate={required()}
		choices={[
			{ id: true, name: 'Показується' },
			{ id: false, name: 'Не показується' },
		]}
	/>,
	<SelectInput
		label='За категорією'
		source='category_id'
		validate={required()}
		choices={[
			{ id: '59c21c24-13f5-4978-9cf4-100aec9d5880', name: 'Цивільним' },
			{ id: 'a07e011a-43f9-49bb-b5d2-47ad2e4d6c50', name: 'Військовим' },
			{ id: '91048c32-43ae-4884-9d11-04ac6ea6eb70', name: 'Розмова' },
			{ id: '9bfaa6dc-3e9e-4aa6-88e3-beb1ca4725aa', name: 'Навчання' },
			{ id: '6e48eef6-efe7-4f6b-8dd2-2eb55ae50ec8', name: 'Психологи' },
		]}
	/>,

	// <SelectInput
	// 	label='За підкатегорією'
	// 	source='sub_category_ids@_has_key'
	// 	choices={[
	// 		{
	// 			id: 'Дитяча психологія',
	// 			name: 'Дитяча психологія',
	// 		},
	// 	]}
	// 	validate={required()}
	// 	filter='Дитяча психологія'
	// />,
	// <SelectSubCategoriesFilter />,
	// <SelectInput
	// 	label='За підкатегорією'
	// 	source='sub_category_ids'
	// 	validate={required()}
	// 	choices={[
	// 		{ id: '59c21c24-13f5-4978-9cf4-100aec9d5880', name: 'Цивільним' },
	// 		{ id: 'a07e011a-43f9-49bb-b5d2-47ad2e4d6c50', name: 'Військовим' },
	// 		{ id: '91048c32-43ae-4884-9d11-04ac6ea6eb70', name: 'Розмова' },
	// 		{ id: '9bfaa6dc-3e9e-4aa6-88e3-beb1ca4725aa', name: 'Навчання' },
	// 		{ id: '6e48eef6-efe7-4f6b-8dd2-2eb55ae50ec8', name: 'Психологи' },
	// 	]}
	// />,
]

const ListActions = () => (
	<TopToolbar>
		<FilterButton filters={postFilters} />
		<CreateButton label='Створити' />
		<ExportButton label='Експорт' />
	</TopToolbar>
)

const TagFilter = () => (
	<Stack direction='row' justifyContent='space-between' alignItems='end'>
		<FilterForm filters={postFilters} />
	</Stack>
)

const DescriptionField: FC<{ label: string }> = ({ label }) => {
	const { description } = useRecordContext()
	const [text, setText] = useState(
		description.length < 100 ? description : `${description.slice(0, 100)}...`
	)
	const [pressed, setPressed] = useState(false)

	return (
		<FunctionField
			label={label}
			render={() => (
				<Box display='flex' flexDirection='column'>
					<span> {text} </span>
					{description.length >= 100 && (
						<Box display='flex' alignItems='flex-start'>
							<Button
								sx={{ mt: 1 }}
								label={pressed ? 'Сховати' : 'Більше'}
								onClick={() => {
									setPressed(!pressed)
									setText(
										pressed ? `${description.slice(0, 100)}...` : description
									)
								}}
							/>
						</Box>
					)}
				</Box>
			)}
		/>
	)
}

const VideosList = () => {
	return (
		<List
			title='Відео'
			sx={{ tableLayout: 'fixed' }}
			actions={<ListActions />}
			filters={<TagFilter />}
			sort={{ field: 'created_at', order: 'DESC' }}
		>
			<Datagrid
				bulkActionButtons={<BulkActionButtons />}
				sx={{
					width: '100%',
					backgroundColor: '#f5f5f5',
				}}
			>
				<ImageField label='Фото' source='cover' />
				<UrlField label='URL' source='video_url' />
				<TextField label='Назва' source='title' />
				<TextField label='Автор' source='author' />
				<TextField label='Категорія' source='category.title' />

				<TextField label='Підкатегорії' source='sub_category_ids' />

				{/* <DescriptionField label='Опис' /> */}
				<PreferenceButtons type='videos' label='Налаштування' />
			</Datagrid>
		</List>
	)
}

export default VideosList
