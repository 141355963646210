import { FC, useState } from 'react'
import {
	Button,
	Datagrid,
	FunctionField,
	ImageField,
	List,
	TextField,
	useRecordContext,
} from 'react-admin'

import { Box } from '@mui/system'
import BulkActionButtons from '../../components/common/BulkActionButtons'
import PreferenceButtons from '../../components/common/PreferenceButtons'

const DescriptionField: FC<{ label: string }> = ({ label }) => {
	const { quote } = useRecordContext()
	const [text, setText] = useState(
		quote.length < 100 ? quote : `${quote.slice(0, 100)}...`
	)
	const [pressed, setPressed] = useState(false)

	return (
		<FunctionField
			label={label}
			render={() => (
				<Box display='flex' flexDirection='column'>
					<span> {text} </span>
					{quote.length >= 100 && (
						<Box display='flex' alignItems='flex-start'>
							<Button
								sx={{ mt: 1 }}
								label={pressed ? 'Сховати' : 'Більше'}
								onClick={() => {
									setPressed(!pressed)
									setText(pressed ? `${quote.slice(0, 100)}...` : quote)
								}}
							/>
						</Box>
					)}
				</Box>
			)}
		/>
	)
}

const BannersList = () => (
	<List
		title='Банери'
		sx={{ tableLayout: 'fixed' }}
		// actions={<ListActions />}
		// filters={<TagFilter />}
		// sort={{ field: 'created_at', order: 'DESC' }}
	>
		<Datagrid
			bulkActionButtons={<BulkActionButtons />}
			sx={{
				width: '100%',
				backgroundColor: '#f5f5f5',
			}}
		>
			<ImageField label='Фото' source='photo' />
			<TextField label="Ім'я" source='name' />
			<TextField label='Позиція' source='position' />
			<TextField label='Порядок' source='order' />
			<DescriptionField label='Опис' />
			<PreferenceButtons type='banners' label='Налаштування' />
		</Datagrid>
	</List>
)

export default BannersList
