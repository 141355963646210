import { FC, useEffect, useLayoutEffect, useRef, useState} from "react";
import styles from './index.module.css';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import TagFacesRoundedIcon from '@mui/icons-material/TagFacesRounded';
import Close from '@mui/icons-material/Close';
import PlayCircle from '@mui/icons-material/PlayCircle';

import {getMessages, InfostandFile, sendMessage} from "../../../lib/firebase";
import { UserI } from "../UserI";
import { useDataProvider } from "react-admin";
import { CircularProgress } from "@mui/material";

import EmojiPicker from 'emoji-picker-react';
import {AttachFile} from "@mui/icons-material";
import Spinner from "../../../components/common/Spinner";
interface Message {
    id: string,
    uid: string,
    text: number,

    file: {
        type: string,
        url: string
    } | null
}

const Chat:FC <{
    chosenUid: string,
    setChosen: (val: string) => void
}> = ({
    chosenUid,
    setChosen
}) => {
    const [message, setMessage] = useState<string>("");
    const [messages, setMessages] = useState<Message[]>([]);
    const [user, setUser] = useState<UserI | null>(null);

    const [blobFile, setBlobFile] = useState<InfostandFile | null>(null);

    const FileInputRef = useRef<HTMLInputElement>(null);

    const [messageIsSending, setMessageIsSending] = useState<boolean>(false);

    const [emojiModal, setEmojiModal] = useState<boolean>(false);
    const [chosenEmoji, setChosenEmoji] = useState<any>(null);

    const onEmojiClick = (emojiObject: any) => {
        setChosenEmoji(emojiObject);
    };

    useEffect(() => {
        if (chosenEmoji) {
            setEmojiModal(false);
            setMessage(message + chosenEmoji.emoji);
        }
    }, [chosenEmoji]);

    const dataProvider = useDataProvider();
    const messagesContainer = useRef<HTMLDivElement>(null);

    const getRandomInt = (min: number, max: number): number => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    };

    useEffect(() => {
        if ( chosenUid ) {
            dataProvider.getOne(
                'users',
                { id: chosenUid }
            ).then(res => {
                const number = getRandomInt(1, res.data.gender === "female" ? 27 : 22);

                setUser({
                    id: res.data.id,
                    name: res.data.name,
                    photo: res.data.photo ? res.data.photo.photo_url : `/imgs/avatars/${number}-${res.data.gender ?? 'male'}.svg`
                });
            });

            const unsubscribe = getMessages(chosenUid, setMessages);
            return unsubscribe;
        }
    }, [chosenUid]);

    useLayoutEffect(() => {
        if ( messagesContainer.current ) {
            messagesContainer.current.scrollTo(0, messagesContainer.current.scrollHeight);
        }
    }, [messagesContainer, messages]);

    return (
        <div
            className={styles.wrapper}
        >
            <div className={styles.userWrapper}>
                <div className={styles.userContainer}>
                    <div style={{
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <ArrowBackRoundedIcon
                            onClick={() => setChosen("")}
                            sx={{
                                display: "flex",
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                color: 'white',
                                height: 30,
                                width: 30
                            }}
                        />

                        {
                            user ? (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: 'center',
                                        color: 'white',
                                        width: '100%',
                                        marginLeft: 20,
                                        gap: 20
                                    }}
                                >
                                    <img style={{
                                        border: '2px solid white',
                                        height: 40,
                                        width: 40,
                                        borderRadius: '50%'
                                    }} src={user.photo} alt={""} />
                                    <div>
                                        {user.name}
                                    </div>
                                </div>
                            ) : (
                                <div style={{width: '100%'}}>
                                    <CircularProgress size={40} sx={{marginLeft: '20px', color: "white"}}/>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            <div
                className={styles.pickerContainer}
                style={{ visibility: emojiModal ? "visible" : "hidden" }}
            >
                <EmojiPicker
                    searchDisabled
                    skinTonesDisabled
                    previewConfig={{
                        showPreview: false
                    }}
                    width={300}
                    onEmojiClick={onEmojiClick}
                />
                <div
                    style={{
                        position: "absolute",
                        backgroundColor: "white",
                        top: -30,
                        right: 0,
                        width: 25,
                        height: 25,
                        boxShadow: '0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)',
                        display: emojiModal ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 100,
                        cursor: "pointer",
                        zIndex: 100000000,
                    }}
                    onClick={() => setEmojiModal(false)}
                >
                    <CloseRoundedIcon sx={{height: 25, width: 25, color: 'black'}}/>
                </div>
            </div>

            <div className={styles.messagesWrapper}>
                <div className={styles.messagesContainer} ref={messagesContainer}>
                    {
                        messages.map((el, index) =>
                            <ChatMessage key={index} index={index} message={el}/>
                        )
                    }
                </div>
            </div>

            <div className={styles.inputWrapper}>
                <div className={styles.inputContainer}>
                    <div style={{
                        cursor: 'pointer',
                        display: "flex",
                        justifyItems: 'center',
                        alignItems: 'center'
                    }}
                         onClick={() => {
                             setEmojiModal(true);
                         }}
                    >
                        <TagFacesRoundedIcon sx={{color: 'white', height: 30, width: 30}}/>
                    </div>

                    <input
                        type="file"
                        value={""}
                        accept=".jpg, .jpeg, .png, .webp, .mp4, .mov"
                        ref={FileInputRef}
                        onChange={(e) => {
                            if ( e.target.files instanceof FileList ) {
                                setBlobFile({
                                    type: e.target.files[0].type.split('/')[0],
                                    file: e.target.files[0],
                                    format: e.target.files[0].type.split('/')[1]
                                });
                            }
                        }}
                        style={{ display: "none" }}
                    />

                    <div
                        style={{
                            height: 30,
                            width: 30,
                            marginLeft: 10,
                            cursor: "pointer",
                            alignItems: 'flex-start'
                        }}
                        onClick={() => {
                            if ( FileInputRef.current ) {
                                FileInputRef.current.click();
                            }
                        }}
                    >
                        <AttachFile sx={{color: 'white', height: 30, width: 30}} />
                    </div>

                    {
                        blobFile && (
                            <div className={styles.blobFileAdded}>
                                {
                                    blobFile.type === 'video' && (
                                        <video
                                            src={URL.createObjectURL(blobFile.file)}
                                        />
                                    )
                                }

                                {
                                    blobFile.type === 'image' && (
                                        <img
                                            src={URL.createObjectURL(blobFile.file)}
                                            alt={''}
                                        />
                                    )
                                }

                                <div className={styles.deleteBlobFile} onClick={() => setBlobFile(null)}>
                                    <Close sx={{color: 'black', height: 20, width: 20, cursor: 'pointer'}} />
                                </div>
                            </div>
                        )
                    }

                    <input
                        disabled={messageIsSending}
                        placeholder={'Відправити повідомлення'}
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === "Enter" && ( message || blobFile ) ) {
                                event.preventDefault();
                                setMessageIsSending(true);
                                sendMessage(chosenUid, message ?? "", blobFile)
                                .then(() => {
                                    setMessage("");
                                    setMessageIsSending(false);
                                    setBlobFile(null);
                                });
                            }
                        }}
                        className={styles.messageInput}
                        multiple
                        type={"text"}
                    />
                    {
                        messageIsSending ? (
                            <div style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                                <Spinner size={40} />
                            </div>
                        ) : (
                            <div style={{
                                cursor: 'pointer',
                                display: "flex",
                                justifyItems: 'center',
                                alignItems: 'center'
                            }}
                                 onClick={() => {
                                     if ( message || blobFile ) {
                                         setMessageIsSending(true)
                                         sendMessage(chosenUid, message ?? "", blobFile)
                                         .then(() => {
                                             setMessage("");
                                             setMessageIsSending(false);
                                             setBlobFile(null);
                                         });
                                     }
                                 }}
                            >
                                <SendRoundedIcon sx={{color: 'white', height: 30, width: 30}}/>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
const ChatMessage:FC <{
    index: number,
    message: Message
}> = ({
    index,
    message
}) => {
    const { file, uid, text }: Message = message;
    const isUserMessage = uid === '' || uid === 'admin';
    const element_id = `infostand-message-chat-element-${index}`;

    const messageMediaContainer = useRef<HTMLDivElement>(null);
    const [mediaIsFullSized, setMediaIsFullSized] = useState(false);

    return (
        <div
            className={styles.messageWrapper}
            id={element_id}
            style={{
                justifyContent: isUserMessage ? "flex-end" : "flex-start"
            }}
        >
            {
                mediaIsFullSized && message.file && (
                    <MediaFullSize file={message.file} hide={() => setMediaIsFullSized(false)}/>
                )
            }

            <div
                className={styles.messageContainer}
                style={{
                    background: isUserMessage
                        ? "#703eff"
                        : "white",
                    color: isUserMessage
                        ? "white"
                        : "black"
                }}
            >
                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 5}} >
                    {
                        file && (
                            <div style={{height: 200, marginBottom: 5, position: 'relative'}} ref={messageMediaContainer}>
                                {
                                    file.type === 'video' && (
                                        <>
                                            <PlayCircle
                                                style={{
                                                    color: 'white',
                                                    height: 50,
                                                    width: 50,
                                                    margin: 'auto',
                                                    position: 'absolute',
                                                    top: 0, left: 0, bottom: 0, right: 0
                                                }}
                                            />
                                            <video
                                                style={{height: '100%', width: '100%', borderRadius: 7}}
                                                src={file.url}
                                                onClick={() => setMediaIsFullSized(true)}
                                                onError={() => {
                                                    if ( text && messageMediaContainer.current ) {
                                                        messageMediaContainer.current.style.display = 'none'
                                                    }
                                                }}
                                            />
                                        </>

                                    )
                                }

                                {
                                   file.type === 'image' && (
                                        <img
                                            style={{height: '100%', width: '100%', borderRadius: 7}}
                                            src={file.url}
                                            onClick={() => setMediaIsFullSized(true)}
                                            onError={() => {
                                                if ( text && messageMediaContainer.current ) {
                                                    messageMediaContainer.current.style.display = 'none'
                                                }
                                            }}
                                            alt={''}
                                        />
                                    )
                                }
                            </div>
                        )
                    }
                    {
                        text && (
                            <p> {text} </p>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

const MediaFullSize:FC<{file: { type: string, url: string }, hide: () => void}> = ({file, hide}) => {
    return (
        <div
            className={styles.fullSizedMediaMask}
        >
            <CloseButton hide={hide} />

            {
                file.type === 'video' && (
                    <video
                        style={{
                            height: "85%",
                            width: "90%",
                            borderRadius: 7,
                            background: 'rgba(0,0,0,1)'
                        }}
                        src={file.url}
                        autoPlay
                        controls
                    />
                )
            }

            {
                file.type === 'image' && (
                    <img
                        style={{
                            maxHeight: "85%",
                            maxWidth: "90%",
                            zIndex: 10000,
                            borderRadius: 7
                        }}
                        src={file.url}
                        alt={''}
                    />
                )
            }
        </div>
    )
}

const CloseButton:FC<{hide: () => void}> = ({hide}) => (
    <div
        style={{
            position: 'absolute',
            zIndex: 10000,
            backgroundColor: "white",
            width: 25,
            height: 25,
            top: '7%',
            right: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: '50%',
            cursor: "pointer",
        }}
        onClick={hide}
    >
        <Close sx={{color: 'black', height: 20, width: 20}} />
    </div>
)

export default Chat;
