import {
	BooleanInput,
	Button,
	Create,
	SimpleForm,
	TextInput,
	Toolbar,
	required,
	useCreate,
	useGetList,
	useNotify,
	useRecordContext,
	useRedirect,
} from 'react-admin'

import { useFormContext } from 'react-hook-form'

import {
	Alert,
	FormControl,
	InputLabel,
	MenuItem,
	Button as MuiButton,
	Select,
	SelectChangeEvent,
} from '@mui/material'
import Box from '@mui/material/Box'
import { FC, useRef, useState } from 'react'
import { UploadVideoSnippet, download, storageRef } from '../../lib/firebase'

const Title = () => {
	const record = useRecordContext()
	return <span> {record?.name} </span>
}

const CustomToolBar: FC<{ video: any }> = ({ video }) => {
	const { getValues } = useFormContext()
	const [create, { isLoading }] = useCreate()
	const notify = useNotify()
	const redirect = useRedirect()

	const SaveHandler = async () => {
		const { name, quote, position, shown, order } = getValues()

		let snippet = video.snippet

		if (video.snippetBlob) {
			const snapshot = await UploadVideoSnippet(
				video.title.replace(/\s/g, ''),
				video.snippetBlob
			)
			snippet = await download(storageRef(snapshot.metadata.fullPath))
		}

		if (!name) {
			notify("Введіть ім'я", { type: 'error' })
			return
		}

		const data = {
			quote,
			position,
			photo: snippet,
			shown,
			name,
			order,
		}

		await create('banners', { data: data })
		redirect('/banners')
	}

	return (
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<Button label='Зберегти' onClick={SaveHandler} disabled={isLoading} />
		</Toolbar>
	)
}

const PositionSetup = () => {
	const record = useRecordContext()
	const { data, total, isLoading, error } = useGetList('banners')

	const { getValues, setValue } = useFormContext()
	const { order } = getValues()
	const [position, setPosition] = useState(0)

	const handleChange = (event: SelectChangeEvent) => {
		setPosition(Number(event.target.value))
		setValue('order', event.target.value)
	}

	return (
		<Box display='flex' flex='row' width='100%' sx={{ mt: 2, mb: 2 }}>
			<FormControl fullWidth>
				<InputLabel id='demo-simple-select-label'>Позиція</InputLabel>
				<Select
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					value={String(order)}
					label='Позиція'
					onChange={handleChange}
				>
					{data?.map((p, i) => (
						<MenuItem value={i + 1}>{i + 1}</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	)
}

const BannersCreate = () => {
	const [video, setVideo] = useState({
		url: '',
		title: '',
		description: '',
		duration: '',
		status: '',
		snippet: '',
		snippetBlob: null,
	})
	const [blobError, setBlobError] = useState<string>('')

	const ImageInputRef = useRef<HTMLInputElement>(null)

	const handleUpload = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			const { height, width } = img

			setBlobError('')
			setVideo(prevState => {
				return {
					...prevState,
					snippet: img.src,
					snippetBlob: event.target.files[0],
				}
			})
		}
	}

	return (
		<Create title={<Title />}>
			<SimpleForm toolbar={<CustomToolBar video={video} />}>
				<Box display='flex' flexDirection='column' width='100%'>
					<Box display='flex' flex='row' gap={3} width='100%'>
						<TextInput
							fullWidth
							label="Ім'я"
							source='name'
							validate={required()}
						/>
						<TextInput
							fullWidth
							label='Позиція'
							source='position'
							validate={required()}
						/>
					</Box>
					<Box display='flex' flex='row' width='100%'>
						<TextInput
							fullWidth
							label='Цитата'
							source='quote'
							validate={required()}
						/>
					</Box>
					<Box display='flex' flex='row' width='100%'>
						<BooleanInput label='Показується' source='shown' />
					</Box>
					<Box display='flex' flex='row' width='100%'>
						<PositionSetup />
					</Box>
					<Box
						display='flex'
						flex='row'
						width='100%'
						sx={{ mt: 3 }}
						alignItems='center'
					>
						<Box sx={{ width: '70%' }}>
							<input
								type='file'
								accept='image/*'
								style={{ display: 'none' }}
								ref={ImageInputRef}
								onChange={handleUpload}
							/>
							<img
								style={{ width: '100%', height: '100%' }}
								src={video.snippet}
								alt={video.title}
							/>

							{blobError && (
								<Alert severity='error' sx={{ mb: 2 }}>
									{blobError}
								</Alert>
							)}

							<Box
								display={'flex'}
								flexDirection={'row'}
								alignItems={'center'}
								justifyContent={'space-between'}
								gap={2}
							>
								<MuiButton
									variant='contained'
									sx={{
										background: '#703eff',
										lineHeight: '18px',
										'&:hover': {
											background: '#703eff',
										},
									}}
									onClick={() => {
										if (ImageInputRef.current) {
											ImageInputRef.current.click()
										}
									}}
								>
									Змінити картинку
								</MuiButton>
								{/* <Typography
									sx={{
										fontSize: 14,
										textAlign: 'end',
										width: '70%',
										whiteSpace: 'pre-line',
									}}
								>
									{
										'Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів'
									}
								</Typography> */}
							</Box>
						</Box>
					</Box>
				</Box>
			</SimpleForm>
		</Create>
	)
}

export default BannersCreate
