import {
    Create,
    SimpleForm,
    Toolbar,
    useRecordContext,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
    required, Button, useCreate, useNotify, useRedirect, SelectInput, useDataProvider
} from 'react-admin';

import Box from '@mui/material/Box';
import {useFormContext} from "react-hook-form";
import {DownloadIconUrl, storageRef, UploadFilterIcon} from "../../lib/firebase";
import {useEffect, useState} from "react";

const Title = () => {
  const record = useRecordContext();
  return <span> {record?.name} </span>;
};

const CustomToolBar = () => {
  const { getValues } = useFormContext();
  const [create, { isLoading }] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();
  const SaveHandler = async () => {
    const { type, name, parent_category_id } = getValues();

    if (!type) {
      notify('Введіть тип', { type: 'error' });
      return;
    } else if (!name) {
        notify('Введіть назву', { type: 'error' });
        return;
    } else if (!parent_category_id) {
        notify('Введіть категорію', { type: 'error' });
        return;
    }


    const data = {
      type, name, parent_category_id
    }

    await create('sub_categories', {data: data});
    redirect('/sub_categories');
  }

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button label="Зберегти" onClick={SaveHandler} disabled={isLoading}/>
    </Toolbar>
  );
};

const SubCategoriesCreate = () => {
    const [categories, setCategories] = useState<{id: string, name: string}[]>([]);
    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider
            .getList('categories', {
                pagination: {
                    page: 1,
                    perPage: 100,
                },
                sort: {
                    order: 'DESC',
                    field: "title",
                },
                filter: true,
            })
            .then(({ data }) => {
                let array = [];

                for (const element of data ) {
                    array.push({ id: element.id, name: element.title });
                }

                setCategories(array);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

  return (
    <Create title={<Title />}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box sx={{ p: 2, mb: 2, background: '#f5f5f5' }}>
            <span>
              <b> Пояснення: </b> називати тип фільтра треба англійською, символ пробіл змінювати на
              тире або знак мінус. <br /> <br />
            </span>
            <span>
              <em> Приклад: </em> example-category
            </span>
          </Box>
          <TextInput label="Тип категорії" source="type" validate={required()} />
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <TextInput
              label="Назва"
              source="name"
              validate={required()}
              sx={{ mr: 1 }}
              fullWidth
            />

          </Box>
            <Box>
                <SelectInput sx={{ width: '100%' }} label="Категорія" source="parent_category_id" choices={categories} />
            </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default SubCategoriesCreate;
