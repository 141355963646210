import { useContext, useEffect, useState } from 'react'
import { AppBar, Layout, Menu, UserMenu, useDataProvider } from 'react-admin'

import FilterAltIcon from '@mui/icons-material/FilterAlt'
import Groups2Icon from '@mui/icons-material/Groups2'
import VideoSettingsIcon from '@mui/icons-material/VideoSettings'
import { Box } from '@mui/material'
import { AccessContext } from '../../containers/access-provider'
import { AuthContext } from '../../lib/contexts'
import LogoutButton from './LogoutButton'

const CustomUserMenu = () => (
	<UserMenu>
		<LogoutButton />
	</UserMenu>
)

const CustomAppBar = () => (
	<AppBar userMenu={<CustomUserMenu />} sx={{ backgroundColor: '#703eff' }} />
)

const CustomDivider = () => <Box sx={{ my: 1 }} />

const CustomMenu = () => {
	const dataProvider = useDataProvider()
	const { setAccesses } = useContext(AccessContext)
	const { currentUser } = useContext(AuthContext)
	const [customAccesses, setCustomAccesses] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (
			dataProvider &&
			dataProvider.getList &&
			dataProvider.getMany &&
			currentUser
		) {
			dataProvider
				?.getList('roles', {})
				.then(({ data }) => {
					if (data) {
						const tables_ids = []

						for (const role of data) {
							if (role.role_name_id === currentUser.role_id) {
								tables_ids.push(role.table_id)
							}
						}

						dataProvider
							?.getMany('tables_names', { ids: tables_ids })
							.then(res => {
								let new_accesses = []
								for (const table of res.data) {
									new_accesses[table.name] = true
								}
								setCustomAccesses(new_accesses)
								setAccesses(new_accesses)
							})
					}
				})
				.catch(error => {
					console.log(error)
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}, [currentUser])

	if (
		loading ||
		typeof dataProvider.getList !== 'function' ||
		typeof dataProvider.getMany !== 'function'
	) {
		return (
			<Menu>
				<Menu.DashboardItem
					sx={{ whiteSpace: 'normal' }}
					primaryText='Завантаження...'
				/>
			</Menu>
		)
	}

	return (
		<Menu>
			<Menu.DashboardItem sx={{ whiteSpace: 'normal' }} primaryText='Головна' />

			<CustomDivider />

			{/*{*/}
			{/*    ( customAccesses["users"] || customAccesses["all"] ) && (*/}
			{/*        <Menu.Item to="/users" primaryText="Користувачі" leftIcon={<AccountCircleIcon />} />*/}
			{/*    )*/}
			{/*}*/}

			{(customAccesses['videos'] || customAccesses['all']) && (
				<Menu.Item
					to='/videos'
					primaryText='Відео'
					leftIcon={<VideoSettingsIcon />}
				/>
			)}
			{(customAccesses['banners'] || customAccesses['all']) && (
				<Menu.Item
					to='/banners'
					primaryText='Банери'
					leftIcon={<VideoSettingsIcon />}
				/>
			)}

			{(customAccesses['categories'] || customAccesses['all']) && (
				<Menu.Item
					to='/categories'
					primaryText='Категорії'
					leftIcon={<FilterAltIcon />}
				/>
			)}

			{(customAccesses['sub_categories'] || customAccesses['all']) && (
				<Menu.Item
					to='/sub_categories'
					primaryText='Підкатегорії'
					leftIcon={<FilterAltIcon />}
				/>
			)}

			{(customAccesses['psychologists'] || customAccesses['all']) && (
				<Menu.Item
					to='/psychologists'
					primaryText='Психологи'
					leftIcon={<Groups2Icon />}
				/>
			)}

			{/*{*/}
			{/*    ( customAccesses["meetings"] || customAccesses["all"] ) && (*/}
			{/*        <Menu.Item to="/meetings" primaryText="Недільні зустрічі" leftIcon={<MapIcon />} />*/}
			{/*    )*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["connect_group"] || customAccesses["connect_group_list"] || customAccesses["all"] ) && <Divider />*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["connect_group"] || customAccesses["all"] ) && (*/}
			{/*        <Menu.Item*/}
			{/*            to="/connect_group"*/}
			{/*            primaryText="Заявки до конект групи"*/}
			{/*            leftIcon={<ConnectWithoutContactIcon />}*/}
			{/*        />*/}
			{/*    )*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["connect_group_list"] || customAccesses["all"] ) && (*/}
			{/*        <Menu.Item*/}
			{/*            to="/connect_group_list"*/}
			{/*            primaryText="Конект групи"*/}
			{/*            leftIcon={<ConnectWithoutContactIcon />}*/}
			{/*        />*/}
			{/*    )*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["volontier_group"] || customAccesses["all"] ) && <Divider />*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["volontier_group"] || customAccesses["all"] ) && (*/}
			{/*        <Menu.Item*/}
			{/*            to="/volontier_group"*/}
			{/*            sx={{ whiteSpace: 'normal' }}*/}
			{/*            primaryText="Заявки до волонтерської групи"*/}
			{/*            leftIcon={<EscalatorWarningIcon />}*/}
			{/*        />*/}
			{/*    )*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["prayers"] || customAccesses["all"] ) && <Divider />*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["prayers"] || customAccesses["all"] ) && (*/}
			{/*        <Menu.Item*/}
			{/*            to="/prayers"*/}
			{/*            sx={{ whiteSpace: 'normal' }}*/}
			{/*            primaryText="Модерація Молитовної стіни"*/}
			{/*            leftIcon={<VolunteerActivismIcon />}*/}
			{/*        />*/}
			{/*    )*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["alerts_to_coach"] || customAccesses["all"] ) && <Divider />*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["alerts_to_coach"] || customAccesses["all"] ) && (*/}
			{/*        <Menu.Item*/}
			{/*            to="/alerts_to_coach"*/}
			{/*            primaryText="Заявки до пасторів"*/}
			{/*            leftIcon={<SupervisorAccountIcon />}*/}
			{/*        />*/}
			{/*    )*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["roles"] || customAccesses["roles_names"] || customAccesses["all"] ) && <Divider />*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["roles"] || customAccesses["all"] ) && (*/}
			{/*        <Menu.Item*/}
			{/*            to="/roles"*/}
			{/*            primaryText="Ролі"*/}
			{/*            leftIcon={<TheaterComedyIcon />}*/}
			{/*        />*/}
			{/*    )*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["roles_names"] || customAccesses["all"] ) && (*/}
			{/*        <Menu.Item*/}
			{/*            to="/roles_names"*/}
			{/*            primaryText="Назви ролей"*/}
			{/*            leftIcon={<AssignmentIndIcon />}*/}
			{/*        />*/}
			{/*    )*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["infostand"] || customAccesses["all"] ) && <Divider />*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["infostand"] || customAccesses["all"] ) && (*/}
			{/*        <Menu.Item*/}
			{/*            to="/infostand"*/}
			{/*            primaryText="Інфостенд"*/}
			{/*            leftIcon={<QuizIcon />}*/}
			{/*        />*/}
			{/*    )*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["analytics"] || customAccesses["all"] ) && (*/}
			{/*        <Menu.Item*/}
			{/*            to="/analytics"*/}
			{/*            primaryText="Аналітика"*/}
			{/*            leftIcon={<AssessmentIcon />}*/}
			{/*        />*/}
			{/*    )*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["meetings_requests"] || customAccesses["all"] ) && <Divider />*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["meetings_requests"] || customAccesses["all"] ) && (*/}
			{/*        <Menu.Item*/}
			{/*            to="/meetings_requests"*/}
			{/*            sx={{ whiteSpace: 'normal' }}*/}
			{/*            primaryText="Реєстрація на недільні зустрічі"*/}
			{/*            leftIcon={<AddLocationAltIcon />}*/}
			{/*        />*/}
			{/*    )*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["ban_requests"] || customAccesses["all"] ) && <Divider />*/}
			{/*}*/}

			{/*{*/}
			{/*    ( customAccesses["ban_requests"] || customAccesses["all"] ) && (*/}
			{/*        <Menu.Item*/}
			{/*            to="/ban_requests"*/}
			{/*            sx={{ whiteSpace: 'normal' }}*/}
			{/*            primaryText="Заявки на відновлення обликового запису"*/}
			{/*            leftIcon={<BlockIcon />}*/}
			{/*        />*/}
			{/*    )*/}
			{/*}*/}
		</Menu>
	)
}

const CustomLayout = props => (
	<Layout {...props} appBar={CustomAppBar} menu={CustomMenu} />
)

export default CustomLayout
