import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {getFunctions, httpsCallable} from 'firebase/functions';
import { getStorage, ref, getDownloadURL, StorageReference, uploadBytes } from 'firebase/storage';

import {
  getFirestore,
  setDoc,
  getDoc,
  getDocs,
  onSnapshot,
  collection,
  addDoc,
  doc,
  query,
  where,
  orderBy,
  serverTimestamp,
  DocumentData
} from "firebase/firestore";
import {Dispatch, SetStateAction} from "react";
import {IVideoStats} from "../pages/analytics/IVideoStats";
import {
  REACT_APP_API_KEY, REACT_APP_APP_ID,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABASE_URL, REACT_APP_MEASUREMENT_ID, REACT_APP_MESSAGE_SENDER_ID,
  REACT_APP_PROJECT_ID, REACT_APP_STORAGE_BUCKET
} from "../constants/constants";


const config = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: REACT_APP_DATABASE_URL,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGE_SENDER_ID,
  appId: REACT_APP_APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID
};

export const app = initializeApp(config);
export const auth = getAuth(app);
export const fns = getFunctions(app);
export const storage = getStorage(app);
export const db = getFirestore(app);

export const storageRef = (folder: string) => ref(storage, folder);
export const download = (reference: any) => getDownloadURL(reference);

export const DownloadIconUrl = (reference: StorageReference) => getDownloadURL(reference);
export const UploadFilterIcon = (name: string, file: Blob) => uploadBytes(
    storageRef(`filters/${name}`), file);

export const UploadVideoSnippet = (name: string, file: Blob) => uploadBytes(
    storageRef(`videos/${name}`), file);

const UploadInfostandFile = (uid: string, name: string, file: Blob, format: string) => uploadBytes(storageRef(`infostand/${uid}/${name}.${format}`), file);

export interface InfostandFile {
  file: Blob,
  type: string,
  format: string
}

export const sendMessage = async (roomId: string, text: string, blob: InfostandFile | null) => {
  try {
    let imageURL = "";

    if ( blob ) {
      const snapshot = await UploadInfostandFile(roomId, new Date().toISOString(), blob.file, blob.format);
      imageURL = await download(storageRef(snapshot.metadata.fullPath));
      const scheduledDeleteFile = httpsCallable(fns, "scheduledDeleteFile");

      await scheduledDeleteFile({
        path: snapshot.metadata.fullPath
      });
    }

    await addDoc(collection(db, 'infostand', roomId, 'messages'), {
      uid: 'admin',
      displayName: 'Dovira Admin',
      photoURL: '',
      text: text.trim(),
      file: blob ? {
        type: blob.type,
        url: imageURL
      } : null,
      createdAt: serverTimestamp(),
      replyId: ''
    });
  } catch (error) {
    console.error(error);
  }
}

export const getMessages = (roomId: string, callback: (value: any[]) => void) => {
  return onSnapshot(
      query(
          collection(db, 'infostand', roomId, 'messages'),
          orderBy('createdAt', 'asc')
      ),
      (querySnapshot) => {
        const messages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        callback(messages);
      }
  );
}

export const getMessagesByRoom = (roomId: string, callback: (value: any[]) => void) => {
  return onSnapshot(
    query(
      collection(db, 'rooms', roomId, 'messages'),
      orderBy('createdAt', 'asc')
    ),
    (querySnapshot) => {
      const messages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(messages);
    }
  );
}
export const readAsAdmin = async (roomId: string) => {
  const docRef = doc(db, "infostand/messages");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const messagesInfo = docSnap.data().info;
    const newMessagesInfo = messagesInfo.filter((el: { id: string }) => el.id !== roomId );

    await setDoc(docRef, {
      info: [{
        id: roomId,
        unread_by_admin: 0
      }, ...newMessagesInfo]
    })
  }
}

export const getInfostandChats = (callback: Dispatch<SetStateAction<{id: string, unread_by_admin: number}[]>>) => {
  return onSnapshot(doc(db, "infostand/messages" ), (doc) => {
    const data: DocumentData | undefined = doc.data();

    if ( data ) {
      callback(data.info);
    }
  });
}

export const getLiveAnanyticsList = (callback: Dispatch<SetStateAction<string[]>>) => {
  return onSnapshot(collection(db, 'live'), (snapshot) => {
    const liveCollectionsList = snapshot.docs.map((doc) => doc.id);
    callback(liveCollectionsList);
  });
}


export const getLiveAnanytics = (roomId: string, callback: Dispatch<SetStateAction<IVideoStats | null>>) => {
  return onSnapshot(
      doc(db, 'live', roomId),
      (querySnapshot) => {
        const live: DocumentData | undefined = querySnapshot.data();
        callback(live as IVideoStats);
      }
  );
}

export const getWatchersList = (roomIds: string[], callback: Dispatch<SetStateAction<number[]>>) => {
  const CHUNK_SIZE = 10;

  const chunks = [];
  for (let i = 0; i < roomIds.length; i += CHUNK_SIZE) {
    chunks.push(roomIds.slice(i, i + CHUNK_SIZE));
  }

  const promises = chunks.map((chunk) => {
    const q = query(collection(db, "live"), where("__name__", "in", chunk));
    return getDocs(q);
  });

  Promise.all(promises)
      .then((snapshots) => {
        const documents: number[] = [];
        snapshots.forEach((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const stats = doc.data();
            if(stats.users != null)
            documents.push(stats.users.length + stats.max_anon_watchers);
          });
        });
        callback(documents);
      })
      .catch((error) => {
        console.log(error);
      });
}

export const createAnanalytics = async (roomId: string) => {
  await setDoc(doc(db, "live", roomId), {
    watchers: 0,
    users: [],
    max_anon_watchers: 0,

    archive: {
      users: [],
      anon_users: 0
    }
  });
}
