import { FC, useRef, useState } from 'react'
import {
	BooleanInput,
	Button,
	DateTimeInput,
	Edit,
	ImageField,
	NumberInput,
	SimpleForm,
	TextInput,
	Toolbar,
	required,
	useNotify,
	useRecordContext,
	useRedirect,
	useUpdate,
} from 'react-admin'

import { Alert, Button as MuiButton, TextField, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useFormContext } from 'react-hook-form'
import FilterInput from '../../components/common/inputs/FilterInput'
import VideoStatusInput from '../../components/common/inputs/VideoStatusInput'
import { UploadVideoSnippet, download, storageRef } from '../../lib/firebase'
import { IBannerTitles } from './IBannerTitles'

const CustomToolBar: FC<{
	blob: Blob | null
}> = ({ blob }) => {
	const form = useFormContext()
	const notify = useNotify()
	const [update, { isLoading }] = useUpdate()
	const redirect = useRedirect()

	const SaveHandler = async () => {
		try {
			const data = form.getValues()
			const { id } = data
			delete data.id

			if (blob) {
				const snapshot = await UploadVideoSnippet(
					data.title.replace(/\s/g, ''),
					blob
				)
				data.cover = await download(storageRef(snapshot.metadata.fullPath))
			}

			await update('videos', { id, data })
			redirect('/videos')
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<Button
				size='medium'
				disabled={isLoading}
				label='Зберегти'
				onClick={SaveHandler}
			/>
		</Toolbar>
	)
}

const BannerSetup = () => {
	const { getValues, setValue } = useFormContext()
	const { banner_title, banner_title_en } = getValues()

	const [bannerTitles, setBannerTitles] = useState<IBannerTitles>({
		banner_title,
		banner_title_en,
	})

	return (
		<Box display='flex' flex='row' width='100%' sx={{ mt: 2, mb: 2 }}>
			<TextField
				sx={{ width: '100%' }}
				label='Банер Укр'
				value={bannerTitles.banner_title}
				onChange={e => {
					setValue('banner_title', e.target.value)
					setBannerTitles({ ...bannerTitles, banner_title: e.target.value })
				}}
			/>
			<TextField
				sx={{ ml: 5, width: '100%' }}
				label='Банер Eng'
				value={bannerTitles.banner_title_en}
				onChange={e => {
					setValue('banner_title_en', e.target.value)
					setBannerTitles({ ...bannerTitles, banner_title_en: e.target.value })
				}}
			/>
		</Box>
	)
}

const AdditionalFields = () => {
	const record = useRecordContext()
	const [status, setStatus] = useState(record.status)
	return (
		<Box
			display='flex'
			flex='row'
			width='100%'
			sx={{ ml: 5 }}
			justifyContent='center'
			alignItems='center'
		>
			<Box display='flex' flexDirection='column' alignContent='center'>
				<VideoStatusInput onChange={e => setStatus(e.target.value)} />

				{status === 'upcoming' && (
					<DateTimeInput source='premiere_at' label="Дата прем'єри" />
				)}

				<TextInput source='duration' label='Тривалість' validate={required()} />
			</Box>
			<Box display='flex' flexDirection='column' sx={{ ml: 5 }}>
				<FilterInput />
				{(status === 'live' || status === 'upcoming') && (
					<>
						<Tooltip title='Посилання на онлайн фойє' arrow>
							<TextInput source='zoom' label='Онлайн Фойє' />
						</Tooltip>
						<Tooltip
							title='Поява онлайн фойє (за скільки хвилин до ефіру)'
							arrow
						>
							<NumberInput
								source='online_foyer_time'
								label='Час входу (хвилини)'
								min={0}
							/>
						</Tooltip>
					</>
				)}
			</Box>
		</Box>
	)
}

const VideosEdit = () => {
	const [blob, setBlob] = useState<Blob | null>(null)
	const [blobError, setBlobError] = useState<string>('')

	const ImageInputRef = useRef<HTMLInputElement>(null)

	const handleUpload = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			const { height, width } = img

			if ((width / height).toFixed(2) !== '1.78') {
				setBlobError('Додайте зображення з відношенням сторін 16 на 9')
				return
			} else if (
				height < 720 ||
				height > 1080 ||
				width < 1280 ||
				width > 1920
			) {
				setBlobError(
					'Зображення повинно бути не менше 1280x720 та не більше 1920х1080 пікселів'
				)
				return
			} else {
				setBlobError('')
				setBlob(event.target.files[0])
			}
		}
	}

	return (
		<Edit title='Редагувати відео' sx={{ tableLayout: 'fixed' }}>
			<SimpleForm toolbar={<CustomToolBar blob={blob} />}>
				<Box display='flex' flexDirection='column' sx={{ ml: 3, mr: 3 }}>
					<Box display='flex' flex='row' width='100%'>
						<TextInput fullWidth label='URL' source='video_url' />
					</Box>
					<Box display='flex' flex='row' width='100%' sx={{ mt: 3 }}>
						<FilterInput />
					</Box>
					<Box>
						<TextInput
							fullWidth
							multiline
							label='Назва Укр'
							validate={required()}
							source='title'
						/>
					</Box>
					<Box>
						<TextInput
							validate={required()}
							source='description'
							multiline
							fullWidth
						/>
					</Box>
					{/* <Box>
						<TextInput fullWidth multiline label='Автор' source='author' />
					</Box> */}
					<Box>
						<TextInput
							label='Лінк на автора'
							source='author_link'
							multiline
							fullWidth
						/>
					</Box>
					<Box display='flex' flex='row' width='100%' gap={3}>
						<BooleanInput label='Показується' source='shown' />
					</Box>

					<Box
						display='flex'
						flex='row'
						width='100%'
						sx={{ mt: 3 }}
						alignItems='center'
					>
						<input
							type='file'
							accept='image/*'
							style={{ display: 'none' }}
							ref={ImageInputRef}
							onChange={handleUpload}
						/>

						<Box sx={{ width: '70%' }}>
							{blob ? (
								<img
									style={{ width: '100%', height: '100%' }}
									src={URL.createObjectURL(blob)}
									alt={''}
								/>
							) : (
								<ImageField
									sx={{
										'& .RaImageField-image': {
											margin: 0,
											width: '100%',
											height: '100%',
										},
									}}
									title='video-snippet'
									source='cover'
								/>
							)}

							{blobError && (
								<Alert severity='error' sx={{ mb: 2 }}>
									{blobError}
								</Alert>
							)}

							<Box
								display={'flex'}
								flexDirection={'row'}
								alignItems={'center'}
								justifyContent={'space-between'}
								gap={2}
							>
								<MuiButton
									variant='contained'
									sx={{
										background: '#703eff',
										lineHeight: '18px',
										'&:hover': {
											background: '#703eff',
										},
									}}
									onClick={() => {
										if (ImageInputRef.current) {
											ImageInputRef.current.click()
										}
									}}
								>
									Змінити картинку
								</MuiButton>
								<Typography
									sx={{
										fontSize: 14,
										textAlign: 'end',
										width: '70%',
										whiteSpace: 'pre-line',
									}}
								>
									{
										'Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів'
									}
								</Typography>
							</Box>
						</Box>
						{/*<AdditionalFields />*/}
					</Box>
				</Box>
			</SimpleForm>
		</Edit>
	)
}

export default VideosEdit
