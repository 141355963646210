import {
	BooleanInput,
	Button,
	Edit,
	ImageField,
	SimpleForm,
	TextInput,
	Toolbar,
	required,
	useGetList,
	useNotify,
	useRecordContext,
	useRedirect,
	useUpdate,
} from 'react-admin'

import { useFormContext } from 'react-hook-form'

import { Alert, FormControl, InputLabel, MenuItem, Button as MuiButton, Select, SelectChangeEvent } from '@mui/material'
import Box from '@mui/material/Box'
import { FC, useRef, useState } from 'react'
import { UploadVideoSnippet, download, storageRef } from '../../lib/firebase'

const Title = () => {
	const record = useRecordContext()
	return <span> {record?.name} </span>
}

const CustomToolBar: FC<{
	blob: Blob | null
}> = ({ blob }) => {
	const form = useFormContext()
	const notify = useNotify()
	const [update, { isLoading }] = useUpdate()
	const redirect = useRedirect()

	const SaveHandler = async () => {
		try {
			const data = form.getValues()
			const { id } = data
			delete data.id

			if (blob) {
				const snapshot = await UploadVideoSnippet(
					data.name.replace(/\s/g, ''),
					blob
				)
				data.photo = await download(storageRef(snapshot.metadata.fullPath))
			}

			await update('banners', { id, data })
			redirect('/banners')
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<Button
				size='medium'
				disabled={isLoading}
				label='Зберегти'
				onClick={SaveHandler}
			/>
		</Toolbar>
	)
}

const PositionSetup = () => {
	const record = useRecordContext()
	const { data } = useGetList('banners')

	const { getValues, setValue } = useFormContext()
	const { order: pos } = getValues()
	const [position, setPosition] = useState(pos)

	const handleChange = (event: SelectChangeEvent) => {
		setPosition(Number(event.target.value))
		setValue('order', event.target.value)
	}

	return (
		<Box display='flex' flex='row' width='100%' sx={{ mt: 2, mb: 2 }}>
			<FormControl fullWidth>
				<InputLabel id='demo-simple-select-label'>Позиція</InputLabel>
				<Select
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					value={position}
					label='Позиція'
					onChange={handleChange}
				>
					{data?.map((p, i) => (
						<MenuItem value={i + 1}>{i + 1}</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	)
}

const BannersEdit = () => {
	const [blob, setBlob] = useState<Blob | null>(null)

	const [blobError, setBlobError] = useState<string>('')

	const ImageInputRef = useRef<HTMLInputElement>(null)

	const handleUpload = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			setBlobError('')
			setBlob(event.target.files[0])
		}
	}

	return (
		<Edit title={<Title />}>
			<SimpleForm toolbar={<CustomToolBar blob={blob} />}>
				<Box display='flex' flexDirection='column' width='100%'>
					<Box display='flex' flex='row' gap={3} width='100%'>
						<TextInput
							fullWidth
							label="Ім'я"
							source='name'
							validate={required()}
						/>
						<TextInput
							fullWidth
							label='Позиція'
							source='position'
							validate={required()}
						/>
					</Box>
					<Box display='flex' flex='row' width='100%'>
						<TextInput
							fullWidth
							label='Цитата'
							source='quote'
							validate={required()}
						/>
					</Box>
					<Box display='flex' flex='row' width='100%'>
						<BooleanInput label='Показується' source='shown' />
					</Box>
					<Box display='flex' flex='row' width='100%'>
						<PositionSetup/>
					</Box>
					<Box
						display='flex'
						flex='row'
						width='100%'
						sx={{ mt: 3 }}
						alignItems='center'
					>
						<input
							type='file'
							accept='image/*'
							style={{ display: 'none' }}
							ref={ImageInputRef}
							onChange={handleUpload}
						/>

						<Box sx={{ width: '70%' }}>
							{blob ? (
								<img
									style={{ width: '100%', height: '100%' }}
									src={URL.createObjectURL(blob)}
									alt={''}
								/>
							) : (
								<ImageField
									sx={{
										'& .RaImageField-image': {
											margin: 0,
											width: '100%',
											height: '100%',
										},
									}}
									title='video-snippet'
									source='photo'
								/>
							)}

							{blobError && (
								<Alert severity='error' sx={{ mb: 2 }}>
									{blobError}
								</Alert>
							)}

							<Box
								display={'flex'}
								flexDirection={'row'}
								alignItems={'center'}
								justifyContent={'space-between'}
								gap={2}
							>
								<MuiButton
									variant='contained'
									sx={{
										background: '#703eff',
										lineHeight: '18px',
										'&:hover': {
											background: '#703eff',
										},
									}}
									onClick={() => {
										if (ImageInputRef.current) {
											ImageInputRef.current.click()
										}
									}}
								>
									Змінити картинку
								</MuiButton>
								{/* <Typography
									sx={{
										fontSize: 14,
										textAlign: 'end',
										width: '70%',
										whiteSpace: 'pre-line',
									}}
								>
									{
										'Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів'
									}
								</Typography> */}
							</Box>
						</Box>
						{/*<AdditionalFields />*/}
					</Box>
				</Box>
			</SimpleForm>
		</Edit>
	)
}

export default BannersEdit
