import gql from 'graphql-tag'

export const GET_LIST_BANNER = gql`
	{
		id
		order
		name
		quote
		position
		photo
		shown
	}
`
export const GET_ONE_BANNER = gql`
	{
		id
		order
		name
		quote
		position
		photo
		shown
	}
`

export const GET_LIST_USER = gql`
	{
		id
		name
		email
		phone
		country
		church
		region
		photo_id
		photo {
			photo_url
		}
		created_at
		gender
		access_level
		coach_id
		connect_id
		connect_group_list {
			name
		}
		banned
		role_id
	}
`

export const GET_ONE_USER = gql`
	{
		id
		name
		email
		phone
		country
		church
		photo {
			photo_url
		}
		created_at
		gender
		access_level
		coach_id
		connect_id
		connect_group_list {
			name
		}
		banned
		role_id
	}
`

export const GET_LIST_VIDEO = gql`
	{
		id
		video_url
		title
		cover
		description
		created_at
		shown
		updated_at
		author
		author_link
		category_id
		sub_category_ids
		category {
			title
			type
		}
		sub_category {
			name
			type
		}
		psychologist {
			fullname
		}
	}
`

export const GET_ONE_VIDEO = gql`
	{
		id
		video_url
		title
		shown
		description
		created_at
		updated_at
		category_id
		cover
		author
		author_link
		sub_category_ids
		category {
			title
			type
		}
		sub_category {
			name
			type
		}
		psychologist {
			fullname
		}
	}
`

export const GET_LIST_CONNECT_GROUP = gql`
	{
		id
		status
		name
		email
		country
		region
		user_id
		created_at
		updated_at
		phone
		group_id
	}
`

export const GET_ONE_CONNECT_GROUP = gql`
	{
		id
		status
		name
		email
		country
		region
		user_id
		created_at
		updated_at
		phone
		group_id
	}
`

export const GET_LIST_VOLONTIER_GROUP = gql`
	{
		id
		status
		name
		email
		country
		region
		talents
		user_id
		created_at
		updated_at
		phone
	}
`

export const GET_ONE_VOLONTIER_GROUP = gql`
	{
		id
		status
		name
		email
		country
		region
		talents
		user_id
		created_at
		updated_at
		phone
	}
`

export const GET_LIST_CONNECT_GROUP_LIST = gql`
	{
		id
		name
		name_en
		main_coach_id
		created_at
	}
`

export const GET_ONE_CONNECT_GROUP_LIST = gql`
	{
		id
		name
		name_en
		main_coach_id
		created_at
	}
`

export const GET_LIST_CATEGORY = gql`
	{
		id
		title
		type
		created_at
		updated_at
		cover
		order
		videos {
			id
			video_url
			title
			description
			created_at
			updated_at
		}
		subCategoriesByParentCategoryId {
			id
			name
			type
		}
	}
`

export const GET_ONE_CATEGORY = gql`
	{
		id
		title
		type
		cover
		created_at
		updated_at
		order
		videos {
			id
			video_url
			title
			description
			created_at
			updated_at
		}
		subCategoriesByParentCategoryId {
			id
			name
			type
		}
	}
`

export const GET_LIST_SUB_CATEGORY = gql`
	{
		id
		name
		type
		parent_category_id
		created_at
		category {
			title
		}
		video {
			video_url
			title
			description
			category_id
			sub_category_id
		}
	}
`

export const GET_ONE_SUB_CATEGORY = gql`
	{
		id
		type
		name
		parent_category_id
		created_at
		video {
			video_url
			title
			description
			category_id
			sub_category_id
		}
	}
`

export const GET_LIST_MEETING = gql`
	{
		id
		name
		name_en
		address
		address_en
		coordinates
		created_at
		updated_at
	}
`

export const GET_ONE_MEETING = gql`
	{
		id
		name
		name_en
		address
		address_en
		coordinates
		created_at
		updated_at
	}
`

export const GET_LIST_MEETING_REQUESTS = gql`
	{
		id
		meeting_id
		user_id
		meeting {
			name
		}
		address
		birthday
		church
		city
		country
		email
		family
		status
		phone
		name
		hospitable
		message
		created_at
		updated_at
	}
`

export const GET_ONE_MEETING_REQUESTS = gql`
	{
		id
		meeting_id
		user_id
		meeting {
			name
		}
		address
		birthday
		church
		city
		country
		email
		family
		status
		phone
		name
		hospitable
		message
		created_at
		updated_at
	}
`

export const GET_LIST_PRAYER = gql`
	{
		id
		text
		type
		user_id
		isAnon
		created_at
		updated_at
		press_count
	}
`

export const GET_ONE_PRAYER = gql`
	{
		id
		text
		type
		user_id
		isAnon
		created_at
		updated_at
		press_count
	}
`

export const GET_LIST_PSYCHOLOGISTS = gql`
	{
		id
		fullname
		about
		photo
		qualification
		facebook
		instagram
		email
		created_at
		updated_at
	}
`

export const GET_ONE_PSYCHOLOGISTS = gql`
	{
		id
		fullname
		about
		photo
		qualification
		facebook
		instagram
		email
		created_at
		updated_at
	}
`
