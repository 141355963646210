import { buildFields } from 'ra-data-hasura'
import { GET_LIST, GET_MANY, GET_ONE, UPDATE, UPDATE_MANY } from 'react-admin'

import {
	GET_LIST_BANNER,
	GET_LIST_CATEGORY,
	GET_LIST_CONNECT_GROUP,
	GET_LIST_CONNECT_GROUP_LIST,
	GET_LIST_MEETING,
	GET_LIST_MEETING_REQUESTS,
	GET_LIST_PRAYER,
	GET_LIST_PSYCHOLOGISTS,
	GET_LIST_SUB_CATEGORY,
	GET_LIST_USER,
	GET_LIST_VIDEO,
	GET_LIST_VOLONTIER_GROUP,
	GET_ONE_BANNER,
	GET_ONE_CATEGORY,
	GET_ONE_CONNECT_GROUP,
	GET_ONE_CONNECT_GROUP_LIST,
	GET_ONE_MEETING,
	GET_ONE_MEETING_REQUESTS,
	GET_ONE_PRAYER,
	GET_ONE_PSYCHOLOGISTS,
	GET_ONE_SUB_CATEGORY,
	GET_ONE_USER,
	GET_ONE_VIDEO,
	GET_ONE_VOLONTIER_GROUP,
} from '../lib/raQueries'

const extractFieldsFromQuery = queryAst =>
	queryAst.definitions[0].selectionSet.selections

const CUSTOM_QUERIES = {
	users: {
		[GET_LIST]: GET_LIST_USER,
		[GET_ONE]: GET_ONE_USER,
		[UPDATE]: GET_ONE_USER,
		[GET_ONE]: GET_ONE_USER,
		[GET_MANY]: GET_LIST_USER,
		[UPDATE_MANY]: GET_LIST_USER,
	},
	banners: {
		[GET_LIST]: GET_LIST_BANNER,
		[GET_ONE]: GET_ONE_BANNER,
		[UPDATE]: GET_ONE_BANNER,
		[GET_ONE]: GET_ONE_BANNER,
		[GET_MANY]: GET_LIST_BANNER,
		[UPDATE_MANY]: GET_LIST_BANNER,
	},
	videos: {
		[GET_LIST]: GET_LIST_VIDEO,
		[GET_ONE]: GET_ONE_VIDEO,
		[UPDATE]: GET_ONE_VIDEO,
		[GET_ONE]: GET_ONE_VIDEO,
		[GET_MANY]: GET_LIST_VIDEO,
		[UPDATE_MANY]: GET_LIST_VIDEO,
	},
	volontier_group: {
		[GET_LIST]: GET_LIST_VOLONTIER_GROUP,
		[GET_ONE]: GET_ONE_VOLONTIER_GROUP,
		[UPDATE]: GET_ONE_VOLONTIER_GROUP,
		[GET_ONE]: GET_ONE_VOLONTIER_GROUP,
		[GET_MANY]: GET_LIST_VOLONTIER_GROUP,
		[UPDATE_MANY]: GET_LIST_VOLONTIER_GROUP,
	},
	connect_group: {
		[GET_LIST]: GET_LIST_CONNECT_GROUP,
		[GET_ONE]: GET_ONE_CONNECT_GROUP,
		[UPDATE]: GET_ONE_CONNECT_GROUP,
		[GET_ONE]: GET_ONE_CONNECT_GROUP,
		[GET_MANY]: GET_LIST_CONNECT_GROUP,
		[UPDATE_MANY]: GET_LIST_CONNECT_GROUP,
	},
	connect_group_list: {
		[GET_LIST]: GET_LIST_CONNECT_GROUP_LIST,
		[GET_ONE]: GET_ONE_CONNECT_GROUP_LIST,
		[UPDATE]: GET_ONE_CONNECT_GROUP_LIST,
		[GET_ONE]: GET_ONE_CONNECT_GROUP_LIST,
		[GET_MANY]: GET_LIST_CONNECT_GROUP_LIST,
		[UPDATE_MANY]: GET_LIST_CONNECT_GROUP_LIST,
	},
	categories: {
		[GET_LIST]: GET_LIST_CATEGORY,
		[GET_ONE]: GET_ONE_CATEGORY,
		[UPDATE]: GET_ONE_CATEGORY,
		[GET_ONE]: GET_ONE_CATEGORY,
		[GET_MANY]: GET_LIST_CATEGORY,
		[UPDATE_MANY]: GET_LIST_CATEGORY,
	},
	sub_categories: {
		[GET_LIST]: GET_LIST_SUB_CATEGORY,
		[GET_ONE]: GET_ONE_SUB_CATEGORY,
		[UPDATE]: GET_ONE_SUB_CATEGORY,
		[GET_ONE]: GET_ONE_SUB_CATEGORY,
		[GET_MANY]: GET_LIST_SUB_CATEGORY,
		[UPDATE_MANY]: GET_LIST_SUB_CATEGORY,
	},
	meetings: {
		[GET_LIST]: GET_LIST_MEETING,
		[GET_ONE]: GET_ONE_MEETING,
		[UPDATE]: GET_ONE_MEETING,
		[GET_ONE]: GET_ONE_MEETING,
		[GET_MANY]: GET_LIST_USER,
		[UPDATE_MANY]: GET_LIST_MEETING,
	},
	meetings_requests: {
		[GET_LIST]: GET_LIST_MEETING_REQUESTS,
		[GET_ONE]: GET_ONE_MEETING_REQUESTS,
		[UPDATE]: GET_ONE_MEETING_REQUESTS,
		[GET_ONE]: GET_ONE_MEETING_REQUESTS,
		[GET_MANY]: GET_LIST_MEETING_REQUESTS,
		[UPDATE_MANY]: GET_LIST_MEETING_REQUESTS,
	},
	prayers: {
		[GET_LIST]: GET_LIST_PRAYER,
		[GET_ONE]: GET_ONE_PRAYER,
		[UPDATE]: GET_ONE_PRAYER,
		[GET_ONE]: GET_ONE_PRAYER,
		[GET_MANY]: GET_LIST_PRAYER,
		[UPDATE_MANY]: GET_LIST_PRAYER,
	},
	psychologists: {
		[GET_LIST]: GET_LIST_PSYCHOLOGISTS,
		[GET_ONE]: GET_ONE_PSYCHOLOGISTS,
		[UPDATE]: GET_ONE_PSYCHOLOGISTS,
		[GET_ONE]: GET_ONE_PSYCHOLOGISTS,
		[GET_MANY]: GET_LIST_PSYCHOLOGISTS,
		[UPDATE_MANY]: GET_LIST_PSYCHOLOGISTS,
	},
}

export const customBuildFields = (type, fetchType) => {
	const resourceName = type.name

	const resourceCustomQueries = CUSTOM_QUERIES[resourceName]

	if (resourceCustomQueries && resourceCustomQueries[fetchType]) {
		return extractFieldsFromQuery(resourceCustomQueries[fetchType])
	}

	return buildFields(type, fetchType)
}

// const hasuraDataProvider = async (type, resource, params) => {
//   const provider = await buildDataProvider({ client: apolloClient }, { buildFields: customBuildFields });
//   return provider(type, resource, params);
// };

// const generalDataProvider = (type, resource, params) => hasuraDataProvider(type, resource, params);

// export default generalDataProvider;
