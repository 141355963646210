import {
    Create,
    SimpleForm,
    Toolbar,
    TextInput,
    Button,
    required,
    useCreate, useNotify, useRedirect, useRefresh, useDataProvider, SelectInput
} from 'react-admin';

import Box from '@mui/material/Box';
import {useFormContext} from "react-hook-form";
import {useEffect, useState} from "react";

const CustomToolBar = () => {
    const { getValues } = useFormContext();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const [create, { isLoading }] = useCreate();

    const CreateHandler = () => {
        const { name, name_en, main_coach_id } = getValues();

        if (!name) {
            notify('Введіть назву українською', { type: 'error' });
            return;
        } else if (!name_en) {
            notify('Введіть назву англійською', { type: 'error' });
            return;
        }

        try {
            create('connect_group_list', { data: { name, name_en, main_coach_id } });

            redirect('/connect_group_list');

            setTimeout(() => {
                refresh();
            }, 1000);

        } catch (e) {
            notify('Помилка!', { type: 'error' });
            console.log(e);
        }
    }

    return (
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button label="Зберегти"
                    disabled={isLoading}
                    size="medium" onClick={CreateHandler} />
        </Toolbar>
    );
};

const CoachInput = () => {
    const [coachUsers, setCoachUsers] = useState([]);
    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider
            .getList('users', {})
            .then(({ data }) => {
                const coachUsers = data.filter((el) => el.access_level === 'coach' && el.connect_id === null );

                const array = [];

                for (const coach of coachUsers) {
                    array.push({ id: coach.id, name: coach.name });
                }

                setCoachUsers(array);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return <SelectInput sx={{ ml: 3, width: '42%' }} label="Головний пастор" source="main_coach_id" choices={coachUsers} />;
};
const ConnectGroupCreate = () => {
    return (
        <Create title="Створити конект групу">
            <SimpleForm toolbar={<CustomToolBar/>}>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flex="row" width="100%">
                        <TextInput sx={{ ml: 3 }} label="Назва конект групи Укр" source="name" validate={required()} />
                        <TextInput sx={{ ml: 3 }} label="Назва конект групи Eng" source="name_en" validate={required()} />
                    </Box>
                    <Box display="flex" flex="row" width="100%">
                        <CoachInput />
                    </Box>
                </Box>
            </SimpleForm>
        </Create>
    );
};

export default ConnectGroupCreate;
