import { Box, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Title } from 'react-admin'

const Dashboard = () => (
	<Card>
		<Title title='Адміністративна Панель' />
		<CardContent
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				height: '84vh',
			}}
		>
			<img
				src='/imgs/ico/dovira-logo.svg'
				alt='hillsong-logo'
				style={{
					width: 150,
					height: 150,
				}}
			/>

			<Box display='flex' alignItems='center'>
				<Typography
					variant='h1'
					component='h1'
					sx={{ fontSize: 46, fontWeight: 500, color: '#703eff' }}
				>
					Адміністративна Панель
				</Typography>
			</Box>

			<Box display='flex' alignItems='center' mt={2}>
				<Typography
					variant='h1'
					component='h1'
					sx={{ fontSize: 46, fontWeight: 500, color: '#703eff' }}
				>
					Dovira UA
				</Typography>
			</Box>
		</CardContent>
	</Card>
)

export default Dashboard
