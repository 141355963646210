import { Alert, Box, Button as MuiButton } from '@mui/material'
import { FC, useRef, useState } from 'react'
import {
	Button,
	DeleteButton,
	Edit,
	ImageField,
	SimpleForm,
	TextInput,
	Toolbar,
	useNotify,
	useRedirect,
	useUpdate,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { UploadVideoSnippet, download, storageRef } from '../../lib/firebase'

const CustomToolBar: FC<{
	blob: Blob | null
}> = ({ blob }) => {
	const form = useFormContext()
	const notify = useNotify()
	const [update, { isLoading }] = useUpdate()
	const redirect = useRedirect()

	const SaveHandler = async () => {
		try {
			const data = form.getValues()
			const { id } = data
			delete data.id
			console.log('data', data)

			if (blob) {
				const snapshot = await UploadVideoSnippet(
					data.email.replace(/\s/g, ''),
					blob
				)
				data.photo = await download(storageRef(snapshot.metadata.fullPath))
			}

			await update('psychologists', { id, data })
			redirect('/psychologists')
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<Button
				size='medium'
				disabled={isLoading}
				label='Зберегти'
				onClick={SaveHandler}
			/>
			<DeleteButton label='Видалити' />
		</Toolbar>
	)
}

export const PsychologistsEdit = () => {
	const [blob, setBlob] = useState<Blob | null>(null)
	const [blobError, setBlobError] = useState<string>('')

	const ImageInputRef = useRef<HTMLInputElement>(null)

	const handleUpload = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			setBlob(event.target.files[0])
		}
	}

	return (
		<Edit title='Редагувати психолога' sx={{ tableLayout: 'fixed' }}>
			<SimpleForm toolbar={<CustomToolBar blob={blob} />}>
				<Box width='100%' display='flex' gap={3} flexDirection='column'>
					<TextInput fullWidth label="Ім'я" source='fullname' />
				</Box>
				<Box width='100%' display='flex' gap={3} flexDirection='column'>
					<TextInput fullWidth label='Кваліфікація' source='qualification' />
				</Box>

				<Box display='flex' gap={3} width='100%' flexDirection='row'>
					<TextInput fullWidth label='Facebook' source='facebook' />
					<TextInput fullWidth label='Instagram' source='instagram' />
					<TextInput fullWidth label='Email' source='email' />
				</Box>

				<Box width='100%'>
					<TextInput multiline fullWidth label='Про психолога' source='about' />
				</Box>

				<Box
					display='flex'
					flex='row'
					width='100%'
					sx={{ mt: 3 }}
					alignItems='center'
				>
					<input
						type='file'
						accept='image/*'
						style={{ display: 'none' }}
						ref={ImageInputRef}
						onChange={handleUpload}
					/>

					<Box sx={{ width: '70%' }}>
						{blob ? (
							<img
								style={{ width: '100%', height: '100%' }}
								src={URL.createObjectURL(blob)}
								alt={''}
							/>
						) : (
							<ImageField
								sx={{
									'& .RaImageField-image': {
										margin: 0,
										width: '100%',
										height: '100%',
									},
								}}
								title='video-snippet'
								source='photo'
							/>
						)}

						{blobError && (
							<Alert severity='error' sx={{ mb: 2 }}>
								{blobError}
							</Alert>
						)}

						<Box
							display={'flex'}
							flexDirection={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}
							gap={2}
						>
							<MuiButton
								variant='contained'
								sx={{
									background: '#703eff',
									lineHeight: '18px',
									'&:hover': {
										background: '#703eff',
									},
								}}
								onClick={() => {
									if (ImageInputRef.current) {
										ImageInputRef.current.click()
									}
								}}
							>
								Змінити картинку
							</MuiButton>
						</Box>
					</Box>
				</Box>
			</SimpleForm>
		</Edit>
	)
}
