export const REACT_APP_IS_DEVELOPMENT = process.env.REACT_APP_IS_DEVELOPMENT === 'true';

export const REACT_APP_GRAPHQL_API_URL = 'https://optimal-flamingo-55.hasura.app/v1/graphql'

export const REACT_APP_GRAPHQL_WS_API_URL = 'wss://optimal-flamingo-55.hasura.app/v1/graphql'

export const REACT_APP_HASURA_ADMIN_SECRET = '9PwQf71mWVQQeuw5TV3dN5dF4gwYVaq5GKVh5xCGFR0PCHhAHuA8YbZqGS7We40A'

export const REACT_APP_API_KEY = "AIzaSyBX5SZzHXVBZNqHvzC46a7OFlSqm-rN7_g"
export const REACT_APP_AUTH_DOMAIN = "dovira-dev-7528c.firebaseapp.com"
export const REACT_APP_DATABASE_URL = "https://dovira-dev-7528c-default-rtdb.europe-west1.firebasedatabase.app"
export const REACT_APP_PROJECT_ID = "dovira-dev-7528c"
export const REACT_APP_STORAGE_BUCKET = "dovira-dev-7528c.appspot.com"
export const REACT_APP_MESSAGE_SENDER_ID = "539764761942"
export const REACT_APP_APP_ID = "1:539764761942:web:0a6a509a840e4eba8be2f7"
export const REACT_APP_MEASUREMENT_ID = "G-TJRQY2MF8R"


// Independent constants
export const REACT_APP_ADMIN_EMAIL = "doviradev@gmail.com"
export const REACT_APP_ADMIN_PASSWORD = "crocnfijeo830s4k93ks-3psk"
export const REACT_APP_YOUTUBE_KEY = "AIzaSyDY6fAPBP1osWPsCipgvA7lR6MOX0Cp2tk"
export const REACT_APP_MAPS_KEY = "AIzaSyCNXGMYFR3G79-4LcB90PGH3s78cJhSiXQ"