import {
  Edit,
  SimpleForm,
  SelectInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRecordContext,
  DateInput,
  TextInput,
  required,
} from 'react-admin';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const Title = () => {
  const record = useRecordContext();
  return <span> {record?.name} </span>;
};

const CustomToolBar = () => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton label="Зберегти" />
      <DeleteButton label="Видалити" />
    </Toolbar>
  );
};


const FamilyField  = () => {
  const { family } = useRecordContext();
  const [ text, setText ] = useState('');

  useEffect(() => {
    switch (family) {
      case "married": {
        setText("одружений/заміжня");
        break;
      }
      case "unmarried": {
        setText("неодружений/незаміжня");
        break;
      }
      case "widower": {
        setText("вдівець/вдова");
        break;
      }
      case "divorced": {
        setText("розлучений/розлучена");
        break;
      }
    }
  }, [family])

  return (
      <TextField
          sx={{ ml: 3, width: '60%' }}
          label="Сімейний статус"
          value={text}
          disabled
      />
  )
}

const HospitableField  = () => {
  const { hospitable } = useRecordContext();
  return (
      <TextField
          sx={{ ml: 3 }}
          label="Чи може прийняти вдома"
          value={hospitable ? "Так" : "Ні"}
          disabled
      />
  )
}

const MeetingField  = () => {
  const navigate = useNavigate();
  const { meeting, meeting_id } = useRecordContext();
  return (
      <Box
          sx={{ position: 'relative', ml: 3, width: '80%' }}
          onClick={() => navigate(`/meetings/${meeting_id}`)}
      >
        <TextField
            sx={{ position: 'absolute', width: '100%' }}
            label="Назва зустрічі"
            value={meeting.name}
        />
      </Box>
  )
}

const MeetingsRequestsEdit = () => {
  return (
    <Edit title={<Title />}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flex="row" width="100%">
            <DateInput sx={{ ml: 3 }} label="Відправлено" disabled source="created_at" />
            <DateInput sx={{ ml: 3 }} label="Оновлено" disabled source="updated_at" />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <TextInput sx={{ ml: 3 }} label="ПІБ" disabled source="name" />
            <TextInput sx={{ ml: 3 }} label="Пошта" disabled source="email" />
            <TextInput sx={{ ml: 3 }} label="Телефон" disabled source="phone" />
            <TextInput sx={{ ml: 3 }} label="Церква" disabled source="church" />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <TextInput sx={{ ml: 3 }} label="Країна" disabled source="country" />
            <TextInput sx={{ ml: 3 }} label="Місто" disabled source="city" />
            <TextInput sx={{ ml: 3 }} label="Адреса" disabled source="address" />
            <HospitableField />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <DateInput sx={{ ml: 3 }} label="Дата нарождення" disabled source="birthday" />
            <FamilyField />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <TextInput sx={{ ml: 3, width: '100%' }} label="Повідомлення" disabled multiline source="message" />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <MeetingField />
            {/*<ReferenceField source="meeting_id" reference="meetings">*/}
            {/*  <TextInput*/}
            {/*      sx={{ ml: 3, width: '100%' }}*/}
            {/*      label="Зустріч"*/}
            {/*      source="address"*/}
            {/*  />*/}
            {/*</ReferenceField>*/}
            <SelectInput
              label="Статус"
              sx={{ ml: 6 }}
              source="status"
              choices={[
                { id: 'pending', name: 'Очікування' },
                { id: 'accepted', name: 'Прийнято' },
                { id: 'rejected', name: 'Відхилино' }
              ]}
              validate={required()}
            />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default MeetingsRequestsEdit;
