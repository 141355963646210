import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button } from '@mui/material'
import { FC } from 'react'
import { EditButton, useDelete, useRecordContext } from 'react-admin'

const PreferenceButtons: FC<{
	type: string
	label: string
	disableEdit?: boolean
	disableDelete?: boolean
}> = ({ label, disableEdit, disableDelete, type }) => {
	const record = useRecordContext()
	console.log('record', record)

	const [deleteOne, { isLoading, error }] = useDelete(type, {
		id: record.id,
		previousData: record,
	})

	const handleDeleteClick = () => {
		const isConfirmed = window.confirm(
			`Are you sure you want to delete this ${type}?`
		)

		if (isConfirmed) {
			deleteOne()
		} else {
			console.log('Delete cancelled')
		}
	}

	return (
		<Box
			display='flex'
			justifyContent='center'
			alignItems='center'
			flexDirection='column'
			gap={2}
		>
			<Box display='flex' alignItems='start' flexDirection='column' gap={'4px'}>
				{disableEdit ? <></> : <EditButton label='Редагувати' />}
				{disableDelete ? (
					<></>
				) : (
					<Button
						color='warning'
						startIcon={<DeleteIcon />}
						disabled={isLoading}
						onClick={handleDeleteClick}
					>
						Видалити
					</Button>
				)}
			</Box>
		</Box>
	)
}

export default PreferenceButtons
