import {
	Alert,
	Button as MuiButton,
	TextField as MuiField,
	TextField,
} from '@mui/material'
import Box from '@mui/material/Box'
import { FC, useRef, useState } from 'react'
import {
	Button,
	Create,
	SimpleForm,
	Toolbar,
	useCreate,
	useNotify,
	useRedirect,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { UploadVideoSnippet, download, storageRef } from '../../lib/firebase'

const CustomToolBar: FC<{ psychologist: any }> = ({ psychologist }) => {
	const redirect = useRedirect()
	const notify = useNotify()
	const form = useFormContext()

	const [create, { isLoading }] = useCreate(undefined, undefined, {
		onSuccess: async data => {},
	})

	const SaveHandler = async () => {
		try {
			const values = form.getValues()

			let snippet = psychologist.photo

			if (psychologist.photoBlob) {
				const snapshot = await UploadVideoSnippet(
					psychologist.name.replace(/\s/g, ''),
					psychologist.photoBlob
				)
				snippet = await download(storageRef(snapshot.metadata.fullPath))
			}

			const data = {
				fullname: psychologist.name,
				about: psychologist.about,
				qualification: psychologist.qualification,
				instagram: psychologist.instagram,
				facebook: psychologist.facebook,
				email: psychologist.email,
				photo: snippet,
			}

			if (!data.fullname) {
				notify("Введіть ім'я", { type: 'error' })
				return
			} else if (!data.qualification) {
				notify('Введіть кваліфікацію', { type: 'error' })
				return
			}

			await create('psychologists', { data })
			redirect('/psychologists')
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<Button
				size='medium'
				disabled={isLoading}
				label='Зберегти'
				onClick={SaveHandler}
			/>
		</Toolbar>
	)
}

export const PsychologistsCreate = () => {
	const [blobError, setBlobError] = useState<string>('')

	const [psychologist, setPsychologist] = useState({
		name: '',
		about: '',
		qualification: '',
		instagram: '',
		facebook: '',
		email: '',
		photo: '',
		photoBlob: '',
	})
	const ImageInputRef = useRef<HTMLInputElement>(null)

	const handleUpload = (event: any) => {
		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			setPsychologist(prevState => {
				return {
					...prevState,
					photo: img.src,
					photoBlob: event.target.files[0],
				}
			})
		}
	}

	return (
		<Create title='Новий психолог' sx={{ tableLayout: 'fixed' }}>
			<SimpleForm toolbar={<CustomToolBar psychologist={psychologist} />}>
				<Box width='100%' display='flex' gap={3} flexDirection='column'>
					<Box display='flex' width='100%'>
						<MuiField
							fullWidth
							label="Ім'я"
							required
							value={psychologist.name}
							onChange={e =>
								setPsychologist({
									...psychologist,
									name: e.target.value,
								})
							}
						/>
					</Box>
					<Box display='flex' width='100%'>
						<MuiField
							fullWidth
							label='Кваліфікація'
							required
							value={psychologist.qualification}
							onChange={e =>
								setPsychologist({
									...psychologist,
									qualification: e.target.value,
								})
							}
						/>
					</Box>

					<Box display='flex' gap={3} flexDirection='row'>
						<MuiField
							fullWidth
							label='Facebook'
							value={psychologist.facebook}
							onChange={e =>
								setPsychologist({
									...psychologist,
									facebook: e.target.value,
								})
							}
						/>
						<MuiField
							fullWidth
							label='Instagram'
							value={psychologist.instagram}
							onChange={e =>
								setPsychologist({
									...psychologist,
									instagram: e.target.value,
								})
							}
						/>
						<MuiField
							fullWidth
							label='Email'
							value={psychologist.email}
							onChange={e =>
								setPsychologist({
									...psychologist,
									email: e.target.value,
								})
							}
						/>
					</Box>

					<Box>
						<TextField
							multiline
							sx={{ width: '100%' }}
							label='Опис'
							required
							value={psychologist.about}
							onChange={e =>
								setPsychologist({ ...psychologist, about: e.target.value })
							}
						/>
					</Box>

					{/*Cover*/}
					<Box
						display='flex'
						flex='row'
						width='100%'
						sx={{ mt: 3 }}
						alignItems='center'
					>
						<Box sx={{ width: '70%' }}>
							<input
								type='file'
								accept='image/*'
								style={{ display: 'none' }}
								ref={ImageInputRef}
								onChange={handleUpload}
							/>
							<img
								style={{ width: '100%', height: '100%' }}
								src={psychologist.photo}
								alt={psychologist.name}
							/>

							{blobError && (
								<Alert severity='error' sx={{ mb: 2 }}>
									{blobError}
								</Alert>
							)}

							<Box
								display={'flex'}
								flexDirection={'row'}
								alignItems={'center'}
								justifyContent={'space-between'}
								gap={2}
							>
								<MuiButton
									variant='contained'
									sx={{
										background: '#703eff',
										lineHeight: '18px',
										'&:hover': {
											background: '#703eff',
										},
									}}
									onClick={() => {
										if (ImageInputRef.current) {
											ImageInputRef.current.click()
										}
									}}
								>
									Змінити фото
								</MuiButton>
							</Box>
						</Box>
					</Box>
				</Box>
			</SimpleForm>
		</Create>
	)
}
